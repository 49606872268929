import { ContainerDataType, ContainersState, ContainerType, GridType } from './typs'
import { ActionsControllers, ActionTriggers } from '../base'
import jwtAxios from '../../auth/jwt-api'
import { message, notification } from "antd";
import setGridsXY from '../../utils/setGridsXY'

export function getActions({ state, dispatch, dispatcher, setState }: ActionsControllers<ContainersState>) {

    async function updateContainers() {
        state.containers.A[0][0].name = "WWW"
        setState({...state})
        // await fetchContainers()
    }

    function setSearch(search: string) {
        dispatch('search', search)
    }

    function setActiveContainerId(id?: number) {
        dispatch('selectedContainerId', id)
    }

    function setSelectedGridName(name?: string) {
        dispatch('selectedGridName', name)
    }

    async function fetchContainers() {
        dispatch('isLoading', true)
        try {
            const res = await jwtAxios("/master/container-matrix");
            // console.log(JSON.parse(JSON.stringify(res.data)))
            // const containers = res?.data && setGridsXY(res.data);
            const containers = res?.data
            // console.log(containers)
            if (!state.currentGrid) {
                return setState({
                    ...state,
                    containers,
                    isLoading: false,
                });
            } else {
                const { x_axis, y_axis, yard } = state.currentGrid;
                const currentGrid = containers[yard][x_axis - 1][y_axis - 1] as GridType;

                const currentContainer = currentGrid?.containers.find((container) => {
                    return currentGrid?.containers.length === container.z_axis
                }) || null;

                setState({ ...state, containers, currentGrid, currentContainer, isLoading: false });
            }
        } catch (error) {
            setState({ ...state, isLoading: false });
        }
    }

    function setCurrentGrid(grid: GridType | null, container?: ContainerType) {
        let currentGrid = grid;
        if (! currentGrid) {
            if (!container?.position.grid_name) return;
            const [area, x, y] = container?.position?.grid_name.split(".") as [keyof ContainerDataType, string, string];
            currentGrid = state.containers[area][+y - 1][+x - 1];
        }
        let currentContainer = null;
        if (container) {
            currentContainer = currentGrid.containers.find(c => currentGrid?.containers.length === c.z_axis) || null;
        } else {
            currentContainer = currentGrid.containers[0] ? currentGrid.containers[0] : null
        }
        setState({ ...state, currentGrid, currentContainer, selectedContainerId: container?.id || container?.container_id });
    }

    async function relocatingContainer(grid: GridType, options?: ActionTriggers) {
        try {
            const response = await jwtAxios.post(`/master/container-relocating`, {
                grid_name: state.currentGrid?.name,
                new_grid_name: grid.name,
                container_number: state.currentContainer?.number
            });
            notification.success({ message: "Container successfully moved!" });
            state.isMoving = false
            await fetchContainers();
            if (options && options.onSuccess) options.onSuccess(response)
        } catch (error) {
            console.log(error);
            notification.error({ message: "Error occured during moving!" });
        }
    }

    async function returningContainer(options?: ActionTriggers) {
        try {
            if (!state.currentGrid?.name) {
                throw new Error('Current grid not found')
            }
            const res = await jwtAxios.post(`/master/container-returning`, {
                grid_name: state.currentGrid.name,
                container_number: state.currentContainer?.number
            });
            notification.success({ message: "Successfully moved out!" });
            await fetchContainers();
            if (options && options.onSuccess) options.onSuccess(res)
        } catch (error) {
            console.log({ error });
            if (options && options.onError) options.onError(error)
            notification.error({ message: "Error occured during moving out!" });
        }
    }

    async function unloadingContainer(options?: ActionTriggers) {
        try {
            if (!state.currentGrid?.name) {
                throw new Error('Current grid not found')
            }
            const res = await jwtAxios.post(`/master/container-unloading`, {
                grid_name: state.currentGrid.name,
                container_number: state.currentContainer?.number
            });
            notification.success({ message: "Successfully moved out!" });
            await fetchContainers();
            if (options && options.onSuccess) options.onSuccess(res)
        } catch (error) {
            console.log({ error });
            if (options && options.onError) options.onError(error)
            notification.error({ message: "Error occured during moving out!" });
        }
    }
    return {
        updateContainers,
        setSelectedGridName,
        setSearch,
        setActiveContainerId,
        fetchContainers,
        setCurrentGrid,
        relocatingContainer,
        returningContainer,
        unloadingContainer
    }
}