import { Button, Form, Input, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { systemRoles } from "../../../shared/constants/Roles";
import { PermissionType } from "../../../shared/types/PermissionType";
import { RoleType } from "../../../shared/types/RoleType";
import Permissions from "../../../components/Permissions";
import jwtAxios from "../../../auth/jwt-api";
import { EnumType } from "../../../shared/types";
import styles from "../index.module.scss";

function Role() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [permissionNames, setPermissionNames] = useState<string[]>([]);
  const [loading,setLoading] = useState(false)
  // get all languages
  const { data: langs, isLoading: isLoading1 } = useQuery("langs", () =>
    jwtAxios.get(`/master/language`)
  );
  const [{ data: role, isLoading: isLoading2 }, setRole] = useState<{
    data: any;
    isLoading: boolean;
  }>({
    data: null,
    isLoading: true,
  });
 
  useEffect(() => {
    if (!id) {
      return setRole({ data: null, isLoading: false });
    }
    (async () => {
      try {
        const data = await jwtAxios.get(`/master/role/${id}`);
        setRole({ data, isLoading: false });
      } catch (error) {
        setRole({ data: null, isLoading: false });
      }
    })();
  }, [id]);

  const initialValues = useMemo(() => {
    if (!langs?.data?.data || (id && !role?.data?.data)) {
      return {};
    }
    id &&
      setPermissionNames(
        Array.from(
          new Set([
            ...permissionNames,
            ...role.data.data.permissions.map(
              (permission: PermissionType) => permission.name
            ),
          ])
        )
      );

    const translations = langs.data.data.map((lang: EnumType) => {
      const translation = role?.data?.data?.translations.find(
        (el: any) => el.language_code === lang.code
      );

      return (
        translation || {
          language_code: lang.code,
        } ||
        []
      );
    });
 
    return { name: role?.data?.data.name, translations };
  }, [langs, role]);

  if (isLoading1 || isLoading2) {
    return <>Loading...</>;
  }

  // Handle form submit
  const onFinish = async (data: any) => {
    setLoading(true)
    data.type = 2
 
     try {
      const res = await jwtAxios[id ? "put" : "post"](
        `/master/role/${id || ""}`,
        data
      );

      await jwtAxios.put(`/master/role-permissions-sync/${id || res?.data?.data.id}`, {
        permissions: permissionNames,
      });

      notification.success({
        message: "notification.success",
      });
      navigate("/admin/table?page=role");
    } catch (error) {
      notification.error({
        message: "notification.error",
      });
      console.dir(error);
    }
    setLoading(false)

  };

  //handle form error
  const onFinishFailed = (error: any) => {
    console.log(error);
  };

 
  return (
    <Form
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      layout="vertical"
    >
      {(id
        ? role?.data?.data?.name && !systemRoles.includes(role.data.data.name)
        : true) && (
        <Form.Item
          label={"Name"}
          validateTrigger={["onChange", "onBlur"]}
          rules={[{ required: true, message: "input.error.fill" }]}
          name={"name"}
        >
          <Input placeholder="Name..." />
        </Form.Item>
      )}
      <div
        className={styles["form-grid"]}
        style={{ gridTemplateColumns: "repeat(2,1fr)" }}
      >
        <Form.List name="translations">
          {(fields) => (
            <>
              {fields.map(({ name, key, ...field }) => {
                return (
                  <Form.Item key={key}>
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      label={`Name (${
                        initialValues?.translations?.[field?.fieldKey || 0]
                          ?.language_code
                      })`}
                      rules={[
                        { required: true, message: "You need to enter name!" },
                      ]}
                      name={[name, "translation"]}
                    >
                      <Input placeholder="Name..." />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label={`Description (${
                        initialValues?.translations?.[field?.fieldKey || 0]
                          ?.language_code
                      })`}
                      validateTrigger={["onChange", "onBlur"]}
                      name={[name, "description"]}   
                    >
                      <Input.TextArea placeholder="Description..." />
                    </Form.Item>
                    <Form.Item
                      style={{ display: "none" }}
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      name={[name, "language_code"]}
                    >
                      <Input />
                    </Form.Item>
                    {/* {field.errors && field.errors[0]?.message && (
                      <div className="ant-form-item-explain">
                        {field.errors[0].message}
                      </div>
                    )} */}
                  </Form.Item>
                );
              })}
            </>
          )}
        </Form.List>
      </div>

      {(id
        ? role?.data?.data?.name && !systemRoles.includes(role.data.data.name)
        : true)&& (
        <Permissions
          permissionNames={permissionNames}
          setPermissionNames={setPermissionNames}
        />
      )}
      <Button
        loading={loading}
        htmlType="submit"
        style={{ marginTop: 50 }}
        type="primary"
      >
        Submit
      </Button>
    </Form>
  );
}

export default Role;
