import { RouteType } from "../shared/types";
import { Routes, Route } from "react-router-dom";

function useRouteGenerator(routes: RouteType[]) {
  return (
    <Routes>
      {routes.map(({ id, path, element }: RouteType) =>
        typeof path === "string" ? (
          <Route key={id} path={path} element={element}></Route>
        ) : (
          path.map((pathString, i) => (
            <Route
              key={`${id}-${i}`}
              path={pathString}
              element={element}
            ></Route>
          ))
        )
      )}
    </Routes>
  );
}

export default useRouteGenerator;
