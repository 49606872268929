import React, { useState } from "react";
import { createContext } from "use-context-selector";
import { makeDispatch, makeDispatcher, makeSelector, ActionsType } from '../base'
import { ContainersState } from "./typs";
import { containersState } from "./state";
import { getActions } from "./actions";


type Actions = ActionsType<ContainersState, typeof getActions>
export const ContainersStatesContext = createContext<ContainersState>(containersState)
export const ContainersActiosContext = createContext<Actions>({} as Actions)
export const useContainerState = makeSelector(ContainersStatesContext)
export const useContainerActions = makeSelector(ContainersActiosContext)


function ContainersProvider({ children }: { children: React.ReactNode }) {
    const [state, setState] = useState(containersState)
    const dispatcher = makeDispatcher(state, setState)
    const dispatch = makeDispatch(state, setState)
    const actions = getActions({ state, dispatch, dispatcher, setState })

    return (
        <ContainersActiosContext.Provider value={{ ...actions, dispatch, dispatcher, setState }}>
            <ContainersStatesContext.Provider value={state}>
                {children}
            </ContainersStatesContext.Provider>
        </ContainersActiosContext.Provider>
    )
}

export default ContainersProvider;
