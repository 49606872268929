import { Button, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import { RiDeleteBinFill } from "react-icons/ri";
import { EnumType, UserType } from "../../../shared/types/index";
import UserModal from "../../../components/Modals/UserModal";
import { PermissionType } from "../../../shared/types/PermissionType";
import {
  ContainerPositionType,
  ContainerType,
} from "../../../providers/ContainersProvider/typs";
import PermissionModal from "../../../components/Modals/PermissionModal";
import {
  deleteablePages,
  editablePages,
  roleAttachablePages,
} from "../../../shared/constants/table";
import RoleModal from "../../../components/Modals/RoleModal";
import { getTextFilter, getDateFilter } from "../../../utils/filters";


const colors = ["green", "yellow", "orange", "red", "coral"];


export const getColumns = (t?: (id: string) => string, filters?: any) => {
  const noColumn = {
    title: "No",
    dataIndex: "no",
    key: "no",
    width: 50,
  };

  return {
    company: [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: true,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...getTextFilter(),

        sorter: true,
      },
      {
        title: "Type",
        dataIndex: "type_name",
        key: "type_name",
        filters: filters?.company_type || [],
        filterSearch: true,
        sorter: true,
      },
      {
        title: "Created by",
        dataIndex: "created_by",
        key: "created_by",
        sorter: true,

        render(user?: { uuid: string; username: string }) {
          return (
            user && <Link to={`/admin/user/${user.uuid}`}>{user.username}</Link>
          );
        },
      },
      {
        title: "Created at",
        dataIndex: "created_at",
        key: "created_at",
        render(data: string) {
          return data.split("T")[0];
        },
        ...getDateFilter(),
        sorter: true,
      },
    ],
    user: [
      noColumn,
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        sorter: true,
        ...getTextFilter(),
      },
      {
        title: "Firstname",
        dataIndex: "firstname",
        key: "firstname",
        sorter: true,
        ...getTextFilter(),
      },
      {
        title: "Lastname",
        dataIndex: "lastname",
        key: "lastname",
        sorter: true,
        ...getTextFilter(),
      },
    ],
    role: [
      noColumn,
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        ...getTextFilter(),
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",

        render: (users: UserType[]) => <UserModal users={users} />,
      },
      {
        title: "Permissions",
        dataIndex: "permissions",
        key: "permissions",
        render: (permissions: PermissionType[]) => (
          <PermissionModal permissions={permissions} />
        ),
      },
      {
        title: "Created at",
        dataIndex: "created_at",
        key: "created_at",
        render(data: string) {
          return data.split("T")[0];
        },
        sorter: true,
      },
    ],
    permission: [
      noColumn,
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        ...getTextFilter(),
      },
    ],
    container: [
      noColumn,
      {
        title: "Container number",
        dataIndex: "number",
        key: "number",
        sorter: true,
        ...getTextFilter(),

        render(number: string, container: ContainerType) {
          return <Link to={`/admin/container-detail/${container.id}`}>{number}</Link>;
        },
      },
      {
        title: "Grid name",
        dataIndex: "position",
        key: "cell",
        render: (data: ContainerPositionType) => data?.grid_name,
        // filters: filters.type_name,
        filterSearch: true,
        ...getTextFilter(),
        sorter: true,
      },
      {
        title: "Floor",
        dataIndex: "position",
        key: "floor",
        render: (data: ContainerPositionType) => data?.z_axis,
        // filters: filters.type_name,
        filterSearch: true,
        ...getTextFilter(),
        sorter: true,
      },
      {
        title: "Type",
        dataIndex: "type_name",
        key: "type",
        // filters: filters.type_name,
        filterSearch: true,
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status",
        // // filters: filters.status_name,
        filterSearch: true,
        sorter: true,
      },
      {
        title: "Consignee",
        dataIndex: "consignee",
        key: "consignee",
        // // filters: filters.consignee,
        filterSearch: true,
        sorter: true,

        render(data: any) {
          return data ? (
            <Link to={`/admin/company/${data.id}`}>{data.name}</Link>
          ) : (
            ""
          );
        },
      },
      {
        title: "Shipper",
        dataIndex: "shipper",
        key: "shipper",
        // // filters: filters.shipper,
        filterSearch: true,
        sorter: true,

        render(data: any) {
          return data ? (
            <Link to={`/admin/company/${data.id}`}>{data.name}</Link>
          ) : (
            ""
          );
        },
      },
      {
        title: "Forwarder",
        dataIndex: "forwarder",
        key: "forwarder",
        // // filters: filters.forwarder,
        filterSearch: true,
        sorter: true,

        render(data: any) {
          return data ? (
            <Link to={`/admin/company/${data.id}`}>{data.name}</Link>
          ) : (
            ""
          );
        },
      },
      {
        title: "Invoice",
        dataIndex: "invoice_number",
        key: "invoice_number",
        sorter: true,
        ...getTextFilter(),
      },
      {
        title: "BL No",
        dataIndex: "bl_number",
        key: "bl_number",
        sorter: true,
        ...getTextFilter(),
      },
      {
        title: "Shipping date",
        dataIndex: "shipping_date",
        key: "shipping_date",
        sorter: true,

        render(data: string) {
          return data?.split(" ")[0];
        },
        ...getDateFilter(),
      },
      {
        title: "ETA ",
        dataIndex: "eta",
        key: "eta ",
        sorter: true,

        render(data: string) {
          return data?.split(" ")[0];
        },
        ...getDateFilter(),
      },
      {
        title: "ATA ",
        dataIndex: "ata",
        key: "ata ",
        sorter: true,

        render(data: string) {
          return data?.split(" ")[0];
        },
        ...getDateFilter(),
      },
    ],
    maincontainer: filters
      ? [
        noColumn,
        {
          title: "Container number",
          dataIndex: "number",
          key: "number",
          sorter: true,
          ...getTextFilter(),

          render(number: string, container: ContainerType) {
            return (
              <Link to={`admin/container/${container.id}`}>{number}</Link>
            );
          },
        },
        {
          title: "Grid name",
          dataIndex: "position",
          key: "cell",
          render: (data: ContainerPositionType) => (
            <div style={{ background:`var(--color-${colors[data?.z_axis - 1 ?? 0]})` }}>
              <div style={{ textAlign: 'center' }}>{data?.grid_name}</div>
            </div>
          ),
          // filters: filters.type_name,
          filterSearch: true,
          ...getTextFilter(),
          sorter: true,
        },
        {
          title: "Floor",
          dataIndex: "position",
          key: "floor",
          render: (data: ContainerPositionType) => (
            <div style={{ background:`var(--color-${colors[data?.z_axis - 1 ?? 1]})` }}>
              <div style={{ textAlign: 'center' }}>{data?.z_axis}</div>
            </div>
          ),
          // filters: filters.type_name,
          filterSearch: true,
          ...getTextFilter(),
          sorter: true,
        },
        {
          title: "Type",
          dataIndex: "type_name",
          key: "type",
          filters: filters.type_name,
          filterSearch: true,
          sorter: true,
        },
        // {
        //   title: "Status",
        //   dataIndex: "status_name",
        //   key: "status",
        //   filters: filters.status_name,
        //   filterSearch: true,
        //   sorter: true,
        // },
        {
          title: "Yuk holati",
          dataIndex: "inside_name",
          key: "inside",
          filters: filters.status_name,
          filterSearch: true,
          sorter: true,
        },
        {
          title: "Consignee",
          dataIndex: "consignee",
          key: "consignee",
          filters: filters.consignee,
          filterSearch: true,
          sorter: true,

          render(data: any) {
            return data ? (
              <Link to={`/admin/company/${data.id}`}>{data.name}</Link>
            ) : (
              ""
            );
          },
        },
        {
          title: "Shipper",
          dataIndex: "shipper",
          key: "shipper",
          filters: filters.shipper,
          filterSearch: true,
          sorter: true,

          render(data: any) {
            return data ? (
              <Link to={`/admin/company/${data.id}`}>{data.name}</Link>
            ) : (
              ""
            );
          },
        },
        {
          title: "Forwarder",
          dataIndex: "forwarder",
          key: "forwarder",
          filters: filters.forwarder,
          filterSearch: true,
          sorter: true,

          render(data: any) {
            return data ? (
              <Link to={`/admin/company/${data.id}`}>{data.name}</Link>
            ) : (
              ""
            );
          },
        },
        {
          title: "Invoice",
          dataIndex: "invoice_number",
          key: "invoice_number",
          sorter: true,
          ...getTextFilter(),
        },
        {
          title: "BL No",
          dataIndex: "bl_number",
          key: "bl_number",
          sorter: true,
          ...getTextFilter(),
        },
        {
          title: "Shipping date",
          dataIndex: "shipping_date",
          key: "shipping_date",
          sorter: true,

          render(data: string) {
            return data?.split(" ")[0];
          },
          ...getDateFilter(),
        },
        {
          title: "ETA ",
          dataIndex: "eta",
          key: "eta ",
          sorter: true,

          render(data: string) {
            return data?.split(" ")[0];
          },
          ...getDateFilter(),
        },
        {
          title: "ATA ",
          dataIndex: "ata",
          key: "ata ",
          sorter: true,

          render(data: string) {
            return data?.split(" ")[0];
          },
          ...getDateFilter(),
        },
      ]
      : [],
  };
};

export const getActions = (
  t: (id: string) => string,
  page: string,
  handleDelete: (id: string) => void,
  { idName = "id", refetch }: { idName: string; refetch: () => void }
) => ({
  title: "Actions",
  dataIndex: idName,
  key: "link",
  width: 180,
  fixed: "right",

  render(y: string, data: any) {
    return (
      <div style={{ display: "flex", gap: 6 }}>
        {roleAttachablePages.includes(page) && (
          <RoleModal user={data} refetch={refetch} />
        )}
        {editablePages.includes(page) && (
          <Link to={`/admin/${page}/${y}`}>
            <Button type="default">
              <AiFillEdit />
            </Button>
          </Link>
        )}

        {deleteablePages.includes(page) &&
          (page === "container" ? !data.position : true) && (
            <Popconfirm
              placement="leftBottom"
              title="Deleting!"
              description={`Are you sure you wanna delete this?`}
              onConfirm={() => handleDelete(y)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" danger>
                <RiDeleteBinFill />
              </Button>
            </Popconfirm>
          )}
      </div>
    );
  },
});
