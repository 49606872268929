import React, { useState } from 'react'
import { MainPropType } from '../../shared/types'
import { Modal, notification, Spin } from "antd";

import styles from "./index.module.scss";
import { useContainerActions, useContainerState } from '../../providers/ContainersProvider/ContainersProvider';
import { useQueryClient } from 'react-query';
import { MatrixGrid } from '../../shared/types/container';
import jwtAxios from '../../auth/jwt-api';

interface UnloadingModalProps {
  children?: React.ReactNode;
  currentGrid: MatrixGrid;
  search: string;
  setCurrentGrid: (matrixGrid: MatrixGrid) => void;
}

function UnloadingModal({ children, currentGrid, search, setCurrentGrid }: UnloadingModalProps) {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const container = currentGrid.containers[0];

  const handleOk = async () => {
    if (loading) return;
    setLoading(true);
    jwtAxios.post<MatrixGrid>(`/master/container-unloading`, {
      grid_name: currentGrid.name,
      container_number: container.number
    })
      .then(res => {
        notification.success({ message: "Successfully moved out!" });
        queryClient.refetchQueries('container-matrix')
        queryClient.refetchQueries(['grid', currentGrid?.name])
        queryClient.refetchQueries('containers-for-setbacking')
        queryClient.refetchQueries(['container-actions', search])
        setOpen(false);
        setLoading(false);
        setCurrentGrid(res.data)
      })
      .catch(err => {
        notification.error({ message: err.response.data.message });

      })
  }

  if (currentGrid?.containers.length == 0) {
    return <></>
  }
  return (
    <>
      <div onClick={() => setOpen(true)}>{children}</div>
      <Modal open={open} onOk={handleOk} onCancel={() => setOpen(false)}>
        <Spin spinning={loading}>
          <p className={styles["move-out-content"]}>
            Are you sure you wanna to send to unloading "
            {container?.number}"
            container?
          </p>
        </Spin>
      </Modal>
    </>
  );
}

export default UnloadingModal