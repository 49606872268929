import { useParams } from "react-router-dom"
import jwtAxios from "../../../auth/jwt-api"
import { useQuery } from "react-query"
import { notification, Table } from "antd"
import moment from "moment"
import { useState } from "react"
import { urlBuilder } from "./helpers"
import { ContainerType, FetchContainerActionType } from "../../../providers/ContainersProvider/typs"


function ContainerShowPage() {
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
    });
    const { id } = useParams<{ id: string }>()
    const { data: container, isLoading } = useQuery(['container-detail', id, tableParams.pagination.current, tableParams.pagination.pageSize], () => {
        return jwtAxios.get<{ data: ContainerType }>(`/master/container/${id}`, {
            params: {
                page: tableParams.pagination.current,
                row: tableParams.pagination.pageSize
            }
        })
    })


    const url = urlBuilder(`/master/container-actions/${id}`, {
        page: tableParams.pagination.current,
        row: tableParams.pagination.pageSize
    })

    const { data: actions } = useQuery(url, () => jwtAxios.get(url), {
        onSuccess(data) {
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: data.data?.meta?.total,
                },
            });
            data?.data?.data.forEach((el: any, index: number) => {
                el.no =
                    tableParams.pagination.current * tableParams.pagination.pageSize -
                    tableParams.pagination.pageSize +
                    index +
                    1;
                el.key = el.id;
            });
        }, onError(err) {
            notification.error({ message: 'Error' })
        }
    })
    return (
        <>
            {!isLoading &&
                <div style={{ fontSize: 16, display: 'flex', justifyContent: 'space-evenly' }}>
                    <div>
                        <div style={{ marginTop: 10 }}>
                            <div>Raqami - "{container?.data.data.number}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Joylashuv - "{(container?.data.data?.position?.grid_name)}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Turi - "{container?.data.data.type_name}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Status - "{container?.data.data.status_name}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Yuk holati - "{container?.data.data.inside_name}"</div>
                        </div>
                    </div>
                    <div>
                        <div style={{ marginTop: 10 }}>
                            <div>Invoice - "{container?.data.data.invoice_number}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>BL No - "{container?.data.data.bl_number}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Forwarder - "{container?.data.data.forwarder?.name}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Shipper - "{container?.data.data.shipper?.name}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Consignee - "{container?.data.data.consignee?.name}"</div>
                        </div>
                    </div>
                    <div>
                        <div style={{ marginTop: 10 }}>
                            <div>Shipping date - "{moment(container?.data.data.shipping_date).format('HH:mm MMM DD YYYY')}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Ata date - "{moment(container?.data.data.ata).format('HH:mm MMM DD YYYY')}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Eta date - "{moment(container?.data.data.eta).format('HH:mm MMM DD YYYY')}"</div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div>Created date - "{moment(container?.data.data.created_at).format('HH:mm MMM DD YYYY')}"</div>
                        </div>
                    </div>
                </div>
            }
            <h2 style={{textAlign: 'center', marginTop: 30}}>Faoliyatlar</h2>
            <Table
                style={{ marginTop: 30 }}
                loading={isLoading}
                size="small"
                dataSource={actions?.data?.data || []}
                pagination={{
                    ...tableParams.pagination,
                    pageSize: tableParams.pagination.pageSize,
                    onChange: (page, pageSize) => {
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                current: page,
                                pageSize: pageSize,
                            },
                        });
                    },
                }}
                columns={[
                    {
                        width: 70,
                        align: 'center',
                        title: "Raqami",
                        dataIndex: "container_number",
                        key: "number",
                        render(data) {
                            return <div style={{ padding: '10px' }}>{data}</div>
                        }
                    },
                    {
                        title: "Yuk holati",
                        dataIndex: "container_inside",
                        key: "number",
                    },
                    {
                        title: "Faoliyat",
                        dataIndex: "action",
                        key: "number",
                        align: 'center'
                    },
                    {
                        title: "Hozirgi joy",
                        dataIndex: "position",
                        key: "number",
                    },
                    {
                        title: "Eski joy",
                        dataIndex: "old_position",
                        key: "number",
                    },
                    {
                        title: "Holati",
                        dataIndex: "container_status",
                        key: "number",
                    },
                    {
                        title: "Turi",
                        dataIndex: "container_type",
                        key: "number",
                    },
                    {
                        title: "Admin",
                        align: 'center',
                        dataIndex: "user_name",
                        key: "number",
                    },
                    {
                        title: "Forwarder",
                        dataIndex: "forwarder_name",
                        key: "number",
                    },
                    {
                        title: "Shipper",
                        dataIndex: "shipper_name",
                        key: "number",
                    },
                    {
                        title: "Consigne",
                        dataIndex: "consignee_name",
                        key: "number",
                    },
                    {
                        title: "Sana va vaqt",
                        dataIndex: "created_at",
                        key: "number",
                        align: 'center',
                        render: (value: string) => <div><span style={{ fontWeight: 700 }}>{moment(value).format('HH:mm')}</span> {moment(value).format('MMM DD YYYY')}</div>
                    },
                ]}
            ></Table>
        </>
    )
}

export default ContainerShowPage