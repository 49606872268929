import { useState } from "react"
import { colors } from "../Grid/components/constants";
import styles from './c3d.module.scss'
import { Popover } from "antd";
import ContainerInfoTable from "./ContainerInfoTable";
import { Draggable, Droppable } from "react-beautiful-dnd";
import clsx from "clsx";
import C3DSvgImage from "./C3DSvgImage";
import { DandActionsEnum, DandPrefixEnum, makeDraggableId } from "../../helpers/draganddrop";
import { Container3D } from "../../shared/types/container";

export interface C3DProps {
    container?: Container3D;
    zIndex?: number;
    isDragging?: boolean;
    dandAction: DandActionsEnum;
    gridName: string
}


function C3D({ container, zIndex, isDragging, dandAction, gridName }: C3DProps) {
    const [w, setW] = useState(328);
    const [h, setH] = useState(140);
    const [x, setX] = useState(1070);
    const [y, setY] = useState(455);

    function fill() {
        return container ? `var(--color-${colors[container.z_axis - 1]})` : `rgba(255,255,255,0.01)`
    }
    return (
        <div style={{ zIndex }}>
            <Popover
                placement="right"
                trigger='hover'
                title={<h2 style={{ textAlign: 'center' }}>{container?.number}</h2>}
                content={<ContainerInfoTable container={container} gridName={gridName} />}
            >
                <div className={clsx(styles.c3d, isDragging && styles.isDragging)} style={{ zIndex }} >
                    <div className={styles.info}>
                        {/* <Draggable key={zIndex} draggableId={makeDraggableId(dandAction, DandPrefixEnum.C3D, String(container?.number))} index={zIndex ?? 1}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                    {snapshot.isDragging
                                        ?
                                        <div style={{ zIndex: 999999 }}>
                                            <C3DSvgImage color={fill()} width={100} height={40} />
                                        </div>
                                        :
                                        container?.number ?? ''
                                    }
                                </div>
                            )}
                        </Draggable> */}
                        {container?.number ?? ''}
                    </div>
                    <div>
                        <C3DSvgImage color={fill()} />
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default C3D