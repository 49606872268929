import { Button } from "antd";
import AuthWrapper from "../AuthWrapper";
import styles from "./signin.module.scss";
import image from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { Checkbox, Form, Input } from "antd";
import { useAuthAction, useAuthData } from "../../../auth/JWTAuthProvider";
import { LoadingOutlined } from "@ant-design/icons";

function Signin() {
  const { signInUser } = useAuthAction();
  const { isLoading } = useAuthData();

  return (
    <AuthWrapper>
      <div className={styles.container}>
        <img src={image} alt="" />

        <Form
          onFinish={signInUser}
          initialValues={{
            rememberMe: true,
           }}
        >
          {/* <Input placeholder="Email:" type="email" name="email" />
          <Input placeholder="Password:" name="password" /> */}

          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Username required",
              },
            ]}
          >
            <Input placeholder={"Username"} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Password required" }]}
          >
            <Input.Password placeholder={"Password"} />
          </Form.Item>
          <Form.Item
            name="rememberMe"
            valuePropName="checked"
            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me </Checkbox> 
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "100%" }}
              htmlType="submit"
              type="primary"
              loading={isLoading}
              icon={isLoading ? <LoadingOutlined spin /> : null}
            >
              Login{" "}
            </Button>
          </Form.Item>
          {/* <div className={styles.socials}>
            <MyButton mode={"outline"} color="grey">
              <AiOutlineGoogle size={20} /> Google
            </MyButton>
            <MyButton mode={"outline"} color="grey">
              <BiLogoFacebookCircle size={20} /> Facebook
            </MyButton>
          </div> */}
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default Signin;
