import React, { useState } from "react";
import { MainPropType } from "../../shared/types";
import SideBar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import styles from "../index.module.scss";
import Layout, { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { Menu, MenuProps } from "antd";
 
type MenuItem = Required<MenuProps>["items"][number];
 

function AdminLayout({ children }: MainPropType) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    //   <div className={styles["admin-layout"]}>
    //     <Navbar />
    //     <div className={styles["sidebar-content"]}>
    //       <SideBar />
    // <div className={styles["content"]}>      {children}</div>
    //     </div>
    //   </div>
    <Layout className={styles["admin-layout"]}>
     <SideBar collapsed={collapsed}  setCollapsed={setCollapsed}/>
      <Layout>
        <Navbar  setCollapsed={setCollapsed} />
        <Content>
          <div className={styles["content"]}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default AdminLayout;
