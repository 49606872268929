import React, { useState } from 'react'
import { Modal, notification, Spin } from "antd";
import styles from "./index.module.scss";
import { useQueryClient } from 'react-query';
import { MatrixGrid } from '../../shared/types/container';
import jwtAxios from '../../auth/jwt-api';

interface UnloadingModalProps {
  states: {
    currentGrid: MatrixGrid;
    search: string;
    unloadModalOpen: boolean;
  },
  actions: {
    setCurrentGrid: (matrixGrid: MatrixGrid | null) => void;
    setUnloadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }
}

function UnloadingModal({ states, actions }: UnloadingModalProps) {
  const { currentGrid, search, unloadModalOpen } = states
  const { setCurrentGrid, setUnloadModalOpen } = actions
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState<boolean>(false);

  const container = currentGrid.containers[0];

  const handleOk = async () => {
    if (loading) return;
    setLoading(true);
    jwtAxios.post<MatrixGrid>(`/master/container-unloading`, {
      grid_name: currentGrid.name,
      container_number: container.number
    })
      .then(res => {
        notification.success({ message: "Successfully moved out!" });
        queryClient.refetchQueries('container-matrix')
        queryClient.refetchQueries(['grid', currentGrid?.name])
        queryClient.refetchQueries('containers-for-setbacking')
        queryClient.refetchQueries(['container-actions', search])
        setUnloadModalOpen(false);
        setLoading(false);
        setCurrentGrid(res.data)
      })
      .catch(err => {
        notification.error({ message: err.response.data.message });

      })
  }

  if (currentGrid?.containers.length == 0) {
    return <></>
  }
  return (
    <>
      <Modal open={unloadModalOpen} onOk={handleOk} onCancel={() => setUnloadModalOpen(false)}>
        <Spin spinning={loading}>
          <p className={styles["move-out-content"]}>
            Are you sure you wanna to send to unloading "
            {container?.number}"
            container?
          </p>
        </Spin>
      </Modal>
    </>
  );
}

export default UnloadingModal