import React, {useState} from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import {useQuery} from 'react-query';
 import PermissionBtn from './PermissionBtn';
import {AiOutlineMinusCircle, AiOutlinePlusCircle} from 'react-icons/ai';
 import { PermissionType } from '../../shared/types/PermissionType';
import jwtAxios from '../../auth/jwt-api';

function Permissions({permissionNames, setPermissionNames}:{permissionNames: string[],setPermissionNames:any} ) {
  const [inputs, setInputs] = useState(['', '']);
 

  const {
    data: permissions,
    isLoading,
    isError,
  } = useQuery('permissions', () => jwtAxios.get(`master/permission`));

  if (isLoading) {
    return <>Loading....</>;
  }
  if (isError) {
    return <>Error in permissions</>;
  }

  const handleAdd = (permission:PermissionType) => {
    setPermissionNames(  Array.from(new Set([...permissionNames, permission.name])) );
   };

  const handleRemove = (permission:PermissionType) => {
    const permissionIndex = permissionNames.indexOf(permission.name);

    if (permissionIndex === -1) {
      return;
    }

    const permissionClone = permissionNames.slice();

    permissionClone.splice(permissionIndex, 1);

    setPermissionNames(permissionClone);
   };


  return (
    <div className={styles.container}>
      <p>permissions</p>
      <div className={styles.cards}>
        <div className={styles.card}>
          {' '}
          <input
            placeholder={'search'}
            value={inputs[0]}
            onChange={(e) => setInputs([e.currentTarget.value, inputs[1]])}
          />
          <div>

          { permissions?.data?.data  
            .sort((a:PermissionType, b:PermissionType) => a.name.localeCompare(b.name))
            .filter(
              (permission:PermissionType) =>
              !permissionNames.includes(permission.name) &&
              permission.name.toLowerCase().includes(inputs[0].toLowerCase()),
              )
              .map((permission:PermissionType) => (
                <PermissionBtn
                setPermissionNames={setPermissionNames}
                permission={permission}
                icon={<AiOutlinePlusCircle />}
                onClick={handleAdd}
                key={permission.id}
                />
                ))}
                </div>
        </div>
        <div className={styles.card}>
          <input
            placeholder={"search"}
            value={inputs[1]}
            onChange={(e) => setInputs([inputs[0], e.currentTarget.value])}
          />
          <div>

          {permissions?.data?.data
            .sort((a:PermissionType, b:PermissionType) => a.name.localeCompare(b.name))
            .filter(
              (permission:PermissionType) =>
              permissionNames.includes(permission.name) &&
              permission.name.toLowerCase().includes(inputs[1].toLowerCase()),
              )
              .map((permission:PermissionType) => (
                <PermissionBtn
                setPermissionNames={setPermissionNames}
                permission={permission}
                icon={<AiOutlineMinusCircle />}
                onClick={handleRemove}
                key={permission.id}
                />
                ))}
                </div>
        </div>
      </div>
    </div>
  );
}

export default Permissions;
 
