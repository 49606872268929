import React, { useState } from "react";
import { Button, Modal } from "antd";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { UserType } from "../../shared/types";

function UserModal({ users }: { users: UserType[] }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <span onClick={() => setOpen(true)} className={styles.button}>
        {users.length}
      </span>
      <Modal
        visible={open}
        title={"Admins"}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Back
          </Button>,
        ]}
      >
        <div className={styles.container}>
          {users.length ? (
            users.map((user) => (
              <Link to={`/admin/user/${user.uuid}?page=user`} key={user.id}>
                {user.firstname} {user.lastname} ({user.username})
              </Link>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>No users</p>
          )}
        </div>{" "}
      </Modal>
    </div>
  );
}

export default UserModal;
