import { Spin } from "antd";
import React from "react";

function Loading({ spinning }: { spinning: boolean }) {
  return (
    <Spin
      spinning={spinning}
      size="large"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    ></Spin>
  );
}

export default Loading;
