import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import { PermissionType } from "../../shared/types/PermissionType";

function PermissionBtn({
  permission,
  onClick,
  icon,
}: {
  permission: PermissionType;
  onClick:(permission: PermissionType) => void;
  icon: React.ReactNode;
}) {
  return (
    <div onClick={() => onClick(permission)} className={styles.button}>
      <p>{permission.name}</p> <span>{icon}</span>
    </div>
  );
}

export default PermissionBtn;

PermissionBtn.propTypes = {
  permission: PropTypes.object,
  setPermissionNames: PropTypes.func,
  onClick: PropTypes.func,
  icon: PropTypes.object,
};
