import React from "react";
import styles from "./index.module.scss";
import trainimage from "../../../assets/images/trainimage.png";
import Grid from "../../Grid";
import { MatrixGrid } from "../../../shared/types/container";
import { DandActionsEnum } from "../../../helpers/draganddrop";



export interface TrainProps {
  actions: {
    setCurrentGrid: (matrixGrid: MatrixGrid) => void;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
  },
  states: {
    currentGrid: MatrixGrid | null
    name: string;
    section: MatrixGrid[][];
    dandAction: DandActionsEnum;
    isDragging: boolean;
    search: string;
    filteredYard: boolean;
  }
}

function Train({ states, actions }: TrainProps) {
  const { name, section, dandAction, currentGrid, isDragging, search, filteredYard } = states;
  const { setCurrentGrid, setIsDragging } = actions;

  return (
    <div style={{ margin: "20px 0" }} className={styles.container}>
      <img src={trainimage} alt="" />
      <div className={styles["yard-b"]}>
        {section.map((column, i) => (
          <Grid key={i}
            states={{ grid: section[i][0], dandAction, currentGrid, isDragging, search, filteredYard }}
            actions={{ setCurrentGrid, setIsDragging }} />
        ))}
      </div>
      <div className={styles.yardname} style={{
        fontSize: '20px', padding: 5
      }}> {name}</div>
    </div>
  );
}

export default React.memo(Train);
