import { ContainerType, GridType } from '../../../providers/ContainersProvider/typs'
import styles from './gridcontainer.module.scss'
import { ribs } from './constants'
import { useContainerActions, useContainerState } from '../../../providers/ContainersProvider/ContainersProvider'
import { MatrixContainer, MatrixGrid } from '../../../shared/types/container'

export interface GridContainerProps {
    container: MatrixContainer
    grid: MatrixGrid
    colors: string[]
}


function GridContainer({ container, colors, grid }: GridContainerProps) {

    // const { setActiveContainerId, setCurrentGrid } = useContainerActions()
    // const { currentGrid, selectedContainerId } = useContainerState()

    function getFill() {
        return ribs[colors[container.z_axis - 1] as keyof typeof ribs]
    }



    return (
        <>
            <div className={styles.root}>
                <div className={styles.label}>
                    <div onClick={() => {
                        // setActiveContainerId(container.container_id)
                        // setCurrentGrid(grid, container)
                    }} className={styles.number} style={{ color: getFill() }}>
                        {container.number}
                    </div>
                </div>
                <svg
                    width="200px"
                    height="100px"
                    viewBox="0 0 200 100"
                    version="1.1"
                    id="svg4757"
                >
                    <defs
                        id="defs4751">
                        <linearGradient
                            id="linearGradient4538">
                            <stop
                                style={{ stopColor: '#c87850', stopOpacity: 1 }}
                                offset="0"
                                id="stop4534" />
                            <stop
                                style={{ stopColor: '#ffc8a0', stopOpacity: 1 }}
                                offset="1"
                                id="stop4536" />
                        </linearGradient>
                        <linearGradient
                            id="linearGradient5351">
                            <stop
                                id="stop5379"
                                offset="0"
                                style={{ stopColor: '#ff0000', stopOpacity: 0 }} />
                            <stop
                                id="stop5381"
                                offset="1"
                                style={{ stopColor: '#ff0000', stopOpacity: 0 }} />
                        </linearGradient>
                        <linearGradient
                            id="linearGradient5351-2">
                            <stop
                                style={{ stopColor: '#ff0000', stopOpacity: 1 }}
                                offset="0"
                                id="stop5347" />
                            <stop
                                style={{ stopColor: '#ffa078', stopOpacity: 0 }}
                                offset="1"
                                id="stop5349" />
                        </linearGradient>
                        <linearGradient
                            id="linearGradient4540"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(9.9999995,2.9000001e-6)"
                            id="linearGradient4540-5"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(20,1.2833334e-6)"
                            id="linearGradient4540-1"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(30,1.2833334e-6)"
                            id="linearGradient4540-9"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(39.999999,1.2833334e-6)"
                            id="linearGradient4540-2"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(49.999999,1.2833334e-6)"
                            id="linearGradient4540-7"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(59.999999,1.2833334e-6)"
                            id="linearGradient4540-3"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(69.999999,1.2833334e-6)"
                            id="linearGradient4540-28"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(79.999999,1.2833334e-6)"
                            id="linearGradient4540-6"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(89.999999,1.2833334e-6)"
                            id="linearGradient4540-19"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(99.999999,1.2833334e-6)"
                            id="linearGradient4540-50"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(110,1.2833334e-6)"
                            id="linearGradient4540-0"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(120,1.2833334e-6)"
                            id="linearGradient4540-06"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(130,1.2833334e-6)"
                            id="linearGradient4540-4"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(140,1.2833334e-6)"
                            id="linearGradient4540-93"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(150,1.2833334e-6)"
                            id="linearGradient4540-54"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                        <linearGradient
                            gradientTransform="translate(160,1.2833334e-6)"
                            id="linearGradient4540-30"
                            x1="15"
                            y1="247"
                            x2="20"
                            y2="247"
                            gradientUnits="userSpaceOnUse" />
                    </defs>
                    <g
                        id="layer1"
                        transform="translate(0,-197)">
                        <rect
                            style={{ fill: `var(--color-${colors[container.z_axis - 1]})`, fillOpacity: 1, stroke: getFill(), strokeWidth: 0.98675436, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5304"
                            width="185"
                            height="90"
                            x="5"
                            y="202"
                            ry="1"
                            rx="1" />



                        <rect
                            style={{ fill: getFill(), fillOpacity: 1, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5306"
                            width="5"
                            height="5"
                            x="5"
                            y="202"
                            ry="1.0000006" />
                        <rect
                            style={{ fill: getFill(), fillOpacity: 1, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5306-3"
                            width="5"
                            height="5"
                            x="185"
                            y="202"
                            ry="1.0000006" />
                        <rect
                            style={{ fill: getFill(), fillOpacity: 1, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5306-5"
                            width="5"
                            height="5"
                            x="185"
                            y="287"
                            ry="1.0000006" />
                        <rect
                            style={{ fill: getFill(), fillOpacity: 1, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5306-6"
                            width="5"
                            height="5"
                            x="5"
                            y="287"
                            ry="1.0000006" />


                        <rect
                            style={{ fillOpacity: 1, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1, fill: 'url(#linearGradient4540)', fillRule: 'nonzero' }}
                            id="rect5335"
                            width="5"
                            height="80"
                            x="15"
                            y="207" />
                        <circle
                            style={{ fill: '#000000', fillOpacity: 0, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="path5406"
                            cx="7.5"
                            cy="204.5"
                            r="1.5" />
                        <circle
                            style={{ fill: '#000000', fillOpacity: 0, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="path5408"
                            cx="7.5"
                            cy="204.5"
                            r="1.5" />
                        <circle
                            style={{ fill: '#ccc', fillOpacity: 0, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="path5410"
                            cx="5"
                            cy="202"
                            r="0.99999994" />
                        <circle
                            style={{ fill: '#000000', fillOpacity: 0.70588237, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="path4499"
                            cx="7.5"
                            cy="204.5"
                            r="1.5" />
                        <circle
                            style={{ fill: '#000000', fillOpacity: 0.70588235, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="path4499-7"
                            cx="187.5"
                            cy="204.5"
                            r="1.5" />
                        <circle
                            style={{ fill: '#000000', fillOpacity: 0.70588235, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="path4499-5"
                            cx="187.5"
                            cy="289.5"
                            r="1.5" />
                        <circle
                            style={{ fill: '#000000', fillOpacity: 0.70588235, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="path4499-3"
                            cx="7.5"
                            cy="289.5"
                            r="1.5" />




                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-0"
                            width="5"
                            height="80"
                            x="15"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-9"
                            width="5"
                            height="80"
                            x="25"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-0"
                            width="5"
                            height="80"
                            x="35"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-06"
                            width="5"
                            height="80"
                            x="45"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-8"
                            width="5"
                            height="80"
                            x="55"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-02"
                            width="5"
                            height="80"
                            x="65"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-92"
                            width="5"
                            height="80"
                            x="75"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-3"
                            width="5"
                            height="80"
                            x="85"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-93"
                            width="5"
                            height="80"
                            x="95"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-84"
                            width="5"
                            height="80"
                            x="105"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-1"
                            width="5"
                            height="80"
                            x="115"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-2"
                            width="5"
                            height="80"
                            x="125"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-5"
                            width="5"
                            height="80"
                            x="135"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-56"
                            width="5"
                            height="80"
                            x="145"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-52"
                            width="5"
                            height="80"
                            x="155"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-4"
                            width="5"
                            height="80"
                            x="165"
                            y="207" />
                        <rect
                            fill={getFill()} style={{ fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect5335-6"
                            width="5"
                            height="80"
                            x="175"
                            y="207" />
                        {/* <rect
                            fill='#000' style={{ fillOpacity: 0.1882353, stroke: 'none', strokeWidth: 1, strokeLinejoin: 'bevel', strokeMiterlimit: 4, strokeDasharray: 'none', strokeOpacity: 1 }}
                            id="rect4734"
                            width="174.99998"
                            height="80"
                            x="10"
                            y="207" /> */}
                    </g>
                </svg>
            </div>
        </>
    )
}

export default GridContainer