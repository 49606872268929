import { notification, Popover } from "antd";
import GridContainer from "./components/GridContainer";
import styles from "./index.module.scss";
import clsx from "clsx";
import { colors } from "./components/constants";
import { MatrixGrid } from "../../shared/types/container";
// import { Draggable } from "react-beautiful-dnd";
// import C3DSvgImage from "../GridInfo/C3DSvgImage";
import { DandActionsEnum } from "../../helpers/draganddrop";
import React, { useEffect, useRef, useState } from "react";
import { packGridForDand, unpackGridForDand } from "./helpers";
import jwtAxios from "../../auth/jwt-api";
import { useQueryClient } from "react-query";


export interface GridProps {
  states: {
    isDraggingOver?: boolean;
    grid: MatrixGrid;
    currentGrid: MatrixGrid | null;
    type?: "buffer";
    dandAction: DandActionsEnum;
    isDragging: boolean;
    search: string;
    filteredYard: boolean;
  },
  actions: {
    setCurrentGrid: (matrixGrid: MatrixGrid) => void;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  }
}

function Grid({ states, actions }: GridProps) {
  const queryClient = useQueryClient()
  const { grid, type, currentGrid, isDragging, search, filteredYard } = states;
  const { setCurrentGrid, setIsDragging } = actions;
  const droppableGridRef = useRef<HTMLDivElement | null>(null)
  const draggableGridRef = useRef<HTMLDivElement | null>(null)
  const [dragOver, setDragOver] = useState(false)

  useEffect(() => {
    if (droppableGridRef.current) {
      droppableGridRef.current.addEventListener('dragover', (e) => {
        e.preventDefault();
        setDragOver(true)
      })
      droppableGridRef.current.addEventListener('dragleave', (e) => {
        setDragOver(false)
      })
      droppableGridRef.current.addEventListener('drop', function (e) {
        e.preventDefault();
        setDragOver(false)
        setIsDragging(false)
        let text = e.dataTransfer?.getData('text/plain')
        if (text) {
          const { containerNumber, currentGridName } = unpackGridForDand(text)
          jwtAxios.post(`/master/container-relocating`, {
            cur_grid_name: currentGridName, // currentGridName,
            des_grid_name: grid.name, // targetGridName,
            container_number: containerNumber, //containerNumber
          })
            .then(res => {
              setCurrentGrid(res.data)
              notification.success({ message: 'Moved successfully' })
              queryClient.refetchQueries('container-matrix')
              queryClient.refetchQueries(['grid', currentGridName])
              queryClient.refetchQueries(['container-actions', search])
            })
            .catch(err => {
              notification.error({ message: err.response.data.message ?? 'Error occured on moving' })
            })
        }
      });
    }
  }, [])

  useEffect(() => {
    if (draggableGridRef.current) {
      draggableGridRef.current.addEventListener('dragstart', function (e) {
        if (currentGrid && currentGrid.containers.length > 0) {
          setIsDragging(true)
          e.dataTransfer?.setData('text/plain', packGridForDand(currentGrid));
        }
      });
      draggableGridRef.current.addEventListener('dragend', function (e) {
        setIsDragging(false)
      })
    }

  }, [currentGrid])


  return (

    <div ref={droppableGridRef}>
      <Popover placement="left" content={(!isDragging && grid.containers.length > 0) &&
        <div className={styles.popover}>
          {/* {grid.name} */}
          {/* {data && selectedContainerId && (selectedContainerId == data.data.data.id) &&
                            <div style={{ width: '250px' }}>
                                <ContainerInfoTable container={data.data?.data} />
                            </div>
                        } */}
          <div>
            {grid?.containers.sort((a, b) => b.z_axis - a.z_axis).map((container, index) => (
              <div key={index}>
                <GridContainer {...{ container, grid, colors }} />
              </div>
            ))}
          </div>
          {/* {(currentGrid?.name == grid.name &&
                            <div>
                                <ContainerActions />
                            </div>)} */}
        </div>
      } title={(!isDragging && grid.containers.length > 0) && <div style={{ textAlign: 'center', fontSize: '20px' }}>{grid?.name}</div>} trigger="hover">
        <div

          // DRAGGABLE OLNY GRID EQUAL TO CURRENT GRID
          draggable={currentGrid?.name == grid.name && grid.containers.length > 0}
          ref={draggableGridRef}


          onClick={() => {
            if (!grid) return
            setCurrentGrid(grid)
          }}

          className={clsx(
            styles.container,
            dragOver && styles.dragOver,
            (currentGrid?.name == grid.name && grid.containers.length > 0) && styles.draggable,
            (currentGrid && currentGrid.name == grid.name) && styles.active,
          )}
          style={{
            background:
              type === "buffer"
                ? grid?.containers.length
                  ? "var(--color-blue)"
                  : "#cfcfcf"
                : grid?.containers.length
                  ? `var(--color-${(filteredYard && search) ? colors[grid.containers[0].z_axis - 1]: colors[grid.containers.length - 1]})`
                  : "white"
          }}
        >
          {
            (grid.containers.length > 0)
              ?
              <div style={{ opacity: '0.6', userSelect: 'none' }}>
                {(filteredYard && search) ? grid.containers[0].number : grid.name}
              </div>
              // <Draggable
              //     key={grid.containers[0]?.id}
              //     draggableId={makeDraggableId(dandAction, DandPrefixEnum.GRID, String(grid.containers[0]?.number))} index={grid.id}>
              //     {(provided, snapshot) => (
              //         <div
              //             {...provided.draggableProps}
              //             {...provided.dragHandleProps}
              //             ref={provided.innerRef}
              //         >
              //             {snapshot.isDragging
              //                 ?
              //                 <div>
              //                     <C3DSvgImage container={grid.containers[0]} width={100} height={40} />
              //                 </div>
              //                 :
              //                 <div style={{ opacity: '0.6', userSelect: 'none' }}>
              //                     {grid.name}
              //                 </div>
              //             }
              //         </div>
              //     )}
              // </Draggable>
              :
              <div style={{ opacity: '0.6', userSelect: 'none' }}>
                {grid.name} {isDragging}
              </div>
          }
        </div>
      </Popover>
    </div>
  )
}

export default React.memo(Grid);
