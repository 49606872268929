import { ObjectType } from "../shared/types";

export const changeFields = (
  datas?: ObjectType[],
  fields?: ObjectType<string>
) => {
  if (!datas) {
    return [];
  }
  if (!fields) {
    return datas;
  }

   
  const result: ObjectType[] = [];
  for (const data of datas) {
    const resultData = { ...data };
    for (const key in fields) {
      const fieldname = fields[key];
      resultData[fieldname] = data[key];
      delete resultData[key];
    }

    result.push(resultData);
  }
  return result;
};
