import { Button, Form, Input, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyType } from "../../../shared/types/CompanyType";
import jwtAxios from "../../../auth/jwt-api";
import styles from "../index.module.scss";

const langs = ["uz", "ru"];

function Permission() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // get all languages
  const { data: langs, isLoading: isLoading1 } = useQuery("langs", () =>
    jwtAxios.get(`/master/language`)
  );
  const [{ data: permission, isLoading }, setPermission] = useState<{
    data?: any;
    isLoading: boolean;
  }>({
    data: null,
    isLoading: true,
  });

  useEffect(() => {
    if (!id) {
      return setPermission({ data: null, isLoading: false });
    }
    (async () => {
      try {
        const data = await jwtAxios.get(`/master/permission/${id}`);
        setPermission({ data, isLoading: false });
      } catch (error) {
        setPermission({ data: null, isLoading: false });
      }
    })();
  }, [id]);

  const initialValues = useMemo(() => {
    if (!langs?.data?.data || (id && !permission?.data?.data)) {
      return {};
    }
    const translations = langs?.data?.data?.map((lang: any) => {
      const translation = permission?.data?.data?.translations.find(
        (el: {
          id: number;
          object_id: number;
          translation: string;
          description: string;
          language_code: string;
        }) => el.language_code === lang.code
      );

      return (
        translation || {
          language_code: lang.code,
        }
      );
    });

    return { name: permission?.data.name, translations };
  }, [langs, permission]);

  if (isLoading || isLoading1) {
    return <>Loading...</>;
  }

  // Handle form submit
  const onFinish = async (data: any) => {
     setLoading(true);

     // if (Math.random() < 12) {
    //   return;
    // }
    try {
      const res = await jwtAxios.put(`/master/permission/${id || ""}`, data);

 
      notification.success({
        message: "notification.success",
      });
      navigate("/admin/table?page=permission");
    } catch (error) {
      notification.error({
        message: "notification.error",
      });
      console.dir(error);
    }
    setLoading(false);
  };

  //handle form error
  const onFinishFailed = (error: any) => {
    console.log(error);
  };

 
  return (
    <Form
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      labelCol={{ span: 4 }}
      // breadcrumb={permission?.data?.name}
      wrapperCol={{ span: 16 }}
      layout="vertical"
    >
      <div className={styles["form-grid"]} style={{gridTemplateColumns:"repeat(2,1fr)"}}>
        <Form.List name="translations">
          {(fields) => (
            <>
              {fields.map(({ name, key, ...field }) => {
                return (
                  <Form.Item key={key}>
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      label={`Name (${
                        initialValues?.translations?.[field?.fieldKey || 0]
                          ?.language_code
                      })`}
                      rules={[
                        { required: true, message: "You need to enter name!" },
                      ]}
                      name={[name, "translation"]}
                    >
                      <Input placeholder="Name..." />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label={`Description (${
                        initialValues?.translations?.[field?.fieldKey || 0]
                          ?.language_code
                      })`}
                      validateTrigger={["onChange", "onBlur"]}
                      name={[name, "description"]}
                    >
                      <Input.TextArea placeholder="Description..." />
                    </Form.Item>
                    <Form.Item
                      style={{ display: "none" }}
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      name={[name, "language_code"]}
                    >
                      <Input />
                    </Form.Item>
                    {/* {field.errors && field.errors[0]?.message && (
                      <div className="ant-form-item-explain">
                        {field.errors[0].message}
                      </div>
                    )} */}
                  </Form.Item>
                );
              })}
            </>
          )}
        </Form.List>
      </div>
      <Button
        loading={loading}
        htmlType="submit"
        style={{ marginTop: 50 }}
        type="primary"
      >
        Submit
      </Button>
    </Form>
  );
}

export default Permission;
