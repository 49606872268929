import React from "react";
import styles from "./index.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";

function Navbar({
  setCollapsed,
}: {
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <nav className={styles["navbar"]}>
      <GiHamburgerMenu  onClick={()=>setCollapsed(prev => !prev)} size={26}/>
    </nav>
  );
}

export default Navbar;
