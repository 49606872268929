import { Navigate } from "react-router-dom";
import { RouteType } from "../../shared/types";
import Error404 from "./Error404";

export const errorRoutes: RouteType[] = [
  { id: -1, path: "/error/not-found", element: <Error404 /> },
  {
    id: 0,
    path: "*",
    element: <Navigate replace to="/error/not-found" />,
  },
];
