import React from "react";
import Containers3d from "./Containers3d";
import InfoTable from "./InfoTable";
import styles from "./index.module.scss";
import { Spin } from "antd";
import { useQuery } from "react-query";
import jwtAxios from "../../auth/jwt-api";
import { useContainerState, useContainerActions } from "../../providers/ContainersProvider/ContainersProvider";
import { MatrixGrid, Grid3D } from "../../shared/types/container";
import { DandActionsEnum } from "../../helpers/draganddrop";

export interface GridInfoProps {
  currentGrid: MatrixGrid | null,
  dandAction: DandActionsEnum
}

function GridInfo({ currentGrid, dandAction }: GridInfoProps) {
  const { data: grid } = useQuery(
    ['grid', currentGrid?.name],
    () => jwtAxios.get<{ data: Grid3D }>(`/master/grid/${currentGrid?.name}`),
    {
      select: (res) => res?.data?.data,
      enabled: !!currentGrid?.name
    }
  );

  return (
    <div className={styles.container}>
      {currentGrid && (grid ? <Containers3d grid={grid} dandAction={dandAction} /> :
        <Spin>
          <div className={styles.row} style={{ height: 30 }}>
            <div className={styles.variable}></div>
            <div className={styles.value}> </div>
          </div>
        </Spin>)}
    </div>
  );
}

export default React.memo(GridInfo);
