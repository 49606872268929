import { AxiosResponse } from "axios";
import { API_URL } from "../../../shared/constants";

type QueryParams = {
    [key: string]: string | number | boolean
}
type UrlParams = (string | null | undefined)[]


export function urlBuilder(path: string, queryParams: QueryParams, urlParams?: UrlParams) {
    let urlPath = path;
    if (urlPath.endsWith('/') && urlPath.length > 1) {
        urlPath = urlPath.slice(0, urlPath.length - 2)
    }
    if (urlParams) {
        urlPath += '/' + urlParams.join('/')
    }
    const entries = Object.entries(queryParams)
    if (entries) {
        urlPath += '?'
        urlPath += `&${entries[0][0]}=${entries[0][1]}`
        for (let i = 1; i < entries.length; i++) {
            urlPath += `&${entries[i][0]}=${entries[i][1]}`
        }
    }
    return urlPath
}


export function downloader(response: AxiosResponse, filename?: string, ) {
    const url = window.URL.createObjectURL(new Blob([response?.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename ?? 'sample.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function downloadWithUrl(url: string, filename?: string) {
    const path = API_URL?.replace('/api', url) as string
    const link = document.createElement('a');
    link.href = path;
    link.setAttribute('download', filename ?? 'sample.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
}