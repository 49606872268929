import { Navigate } from "react-router-dom";
import Siginin from "../auth/Signin";
import { errorRoutes } from "../error";
import MainPage from "./MainPage/MainPage";
import { RouteType } from "../../shared/types";



export const mainRoutes: RouteType[] = [
    {
        id: 1,
        path: ["/", "/home"],
        element: <MainPage />
    }
] 