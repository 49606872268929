import React, { useState } from "react";
import jwtAxios from "../../../auth/jwt-api";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Button, Form, Input, Select, notification } from "antd";
import { DefaultOptionType } from "antd/es/select";
import styles from "../index.module.scss";
import { useForm } from "antd/es/form/Form";
function Admin() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [form ] = useForm()
  const navigate = useNavigate();
  const { data: user, isLoading } = useQuery(
    ["user", id],
    () => jwtAxios.get(`master/user/${id}`),
    {
      enabled: !!id,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const { data: employes } = useQuery(
    ["employes"],
    () => jwtAxios.get(`master/ad-users`),
    {
      enabled: !id,
    }
  );

  

  const handleSubmit = async (data: any) => {
 
     setLoading(true);
    try {
  
      data.username = id ? undefined : data.username.slice(0, -4) 
      await jwtAxios[id ?"put" : 'post'](`master/user/${id || ""}`, data);
      notification.success({
        message: `Successfully ${id ? "changed" : "updated"}`,
      });
      navigate("/admin/table?page=user");
    } catch (error) {
      console.log({ error });
      notification.error({
        message: `Cannot ${id ? "change" : "update"} user`,
      });
    }
    setLoading(false);

};
 
   if (isLoading) {
    return <>Loading...</>;
  }
  const handleChange = (data: any) => {
     
    const employe = employes?.data?.find((employe:any) => employe.username == data.slice(0, -4)   )


     form.setFieldsValue({...employe,username:data})
    
  };
  return (
    <div>
      <h1 style={{ paddingBottom: 30 }}>Company</h1>
      <Form form={form}
        initialValues={    user?.data.data  }
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className={styles["form-grid"]}>
          {id ? (
            ""
          ) : (
            <Form.Item
              name={"username"}
              label="User"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Select
                showSearch // Enable search functionality
                placeholder="Select a user"
                onChange={handleChange}
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(employes?.data || []).map((employe: any) => ({
                  value: `${employe.username}-${
                    Math.floor(Math.random() * 900) + 100
                  }`,
                  label: employe.username,
                }))}
              ></Select>
            </Form.Item>
          )}
          
          <Form.Item
            name={"firstname"}
            label="Firstname"
           >
            <Input />
          </Form.Item>{" "}
          <Form.Item
            name={"lastname"}
            label="Lastname"
           >
            <Input />
          </Form.Item>
        </div>

        <Button loading={loading} htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default Admin;
