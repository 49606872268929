import { ColumnType } from "antd/es/table";
import { ContainerType } from "../../providers/ContainersProvider/typs";
import { Button, DatePicker, Input, Space } from "antd";

export const getDateFilter = (): ColumnType<ContainerType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            style={{ marginBottom: 8 }}
            onChange={(data) => {
              if (!data || !data[0] || !data[1]) {
                setSelectedKeys([]);
                confirm();
                return;
              }

              const date1 = data[0].format("YYYY-MM-DD");
              const date2 = data[1].format("YYYY-MM-DD");
              setSelectedKeys([date1 === date2 ? date1 : `${date1}|${date2}`]);
              confirm();
            }}
          />
          <br />
          <Space>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                close();
                confirm();
              }}
            >
              Close
            </Button>
          </Space>
        </div>
      );
    },
  });
  export const getTextFilter = (): ColumnType<ContainerType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {

      return (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(data) => {
              const text = data.currentTarget.value || "";

              setSelectedKeys(text ? [text] : []);
            }}
            onPressEnter={(data) => {
              const text = data.currentTarget.value || "";

              setSelectedKeys(text ? [text] : []);
              confirm();
            }}
          />
          <br />
          <br />
          <Space>
            <Button
              size="small"
              type="link"
              onClick={() => {
                setSelectedKeys([]);
                confirm();
              }}
            >
              Reset
            </Button>{" "}
            <Button
              size="small"
              type="primary"
              onClick={() => {
                close();
              }}
            >
              Close
            </Button>{" "}
          </Space>
        </div>
      );
    },
  });