import axios from "axios";
import { API_URL } from "../shared/constants";

const jwtAxios = axios.create({
  baseURL: API_URL, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.message === "Unauthenticated.") {
       localStorage.removeItem("token")
      window.location.replace('/auth/signin')
    }
    return Promise.reject(err);
  }
);

export const setAuthToken = (token?: string,rememberMe:boolean = true) => {
  if (token) {
    jwtAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    rememberMe && localStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export default jwtAxios;
