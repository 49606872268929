 import { useNavigate } from "react-router-dom";
import image from "../../../assets/images/404.png";
 
function Error404() {
  const navigate = useNavigate()
  return (
    <div
      style={{
        height: "calc(100vh - 89px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={image}
        alt=""
        style={{
          maxWidth: 600,
          width: "100%",
          objectFit: "contain",
          mixBlendMode: "darken",
        }}
      />
      <p
        style={{
          color: "grey",
          marginTop: 20,
          textAlign: "center",
          lineHeight: "160%",
          maxWidth: 500,
        }}
      >
        The page you are looking for may have been moved, deleted or possibly
        never existed <span style={{color:"#2132cf",cursor:"pointer"}} onClick={()=>navigate(-1)}>Back to page</span>
      </p>
    </div>
  );
}

export default Error404;
