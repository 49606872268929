import React, { useState } from "react";
import styles from "./index.module.scss";
import Sider from "antd/es/layout/Sider";
import { Menu } from "antd";
import Logo from "./Logo";
import { menuConfig } from "../../../pages/admin/menuConfig";
import { MenuRouteType } from "../../../shared/types";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAuthAction } from "../../../auth/JWTAuthProvider";
import clsx from "clsx";
import { BiLogOut } from "react-icons/bi";

function SideBar({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { logout } = useAuthAction();
  const { pathname, search } = useLocation();

  const isActive = (id: string) => {
    return pathname.includes(id) || search.includes(id);
  };

  return (
    <Sider
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light"
      className={clsx(styles.sidebar, collapsed && styles.active)}
    >
      <Logo />
      <Menu mode="inline" theme="light">
        <p className={styles.section}>Admin</p>
        {menuConfig.map((menu: MenuRouteType) => (
          <Menu.Item
            key={menu.id}
            icon={menu.icon}
            // className={
            //   isActive(menu.id as string) ? "ant-menu-item-selected" : ""
            // }
          >
            <Link to={menu.link} className={styles.menuitem}>
              {menu.label}
            </Link>
          </Menu.Item>
        ))}
        <Menu.Item key={"12"} icon={<BiLogOut />} onClick={logout}>
          {" "}
          <p className={styles.menuitem}>Logout</p>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default SideBar;
