import { RouteType } from "../shared/types";
import { adminRoutes } from "./admin";
import { authRoutes } from "./auth";
import { errorRoutes } from "./error";
import { mainRoutes } from "./main";

export const unauthorithedRoutes = [...authRoutes, ...errorRoutes];

export const authorithedRoutes: RouteType[] = [
  ...mainRoutes,
  ...adminRoutes,
  ...errorRoutes,
];
