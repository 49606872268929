

export const colors = ["green", "yellow", "orange", "red", "coral"];

export const ribs = {
    green: '#46aa70',
    yellow: '#b9af20',
    orange: '#99840a',
    red: '#c93333',
    coral: '#b87b7b'
}