import React, { useEffect, useState } from "react";
import { EnumType, MainPropType } from "../../shared/types";
import { Form, Modal, Select, notification } from "antd";
import { useQuery, useQueryClient } from "react-query";
import jwtAxios from "../../auth/jwt-api";
import styles from "./index.module.scss";
import { ContainerType } from "../../providers/ContainersProvider/typs";
import { useForm } from "antd/es/form/Form";
import getOptionsfrom from "../../utils/getOptionsFrom";
import { useContainerActions, useContainerState } from "../../providers/ContainersProvider/ContainersProvider";
import moment from "moment";
import { MatrixGrid } from "../../shared/types/container";

type FormType = { container_number_list: string[], inside: number }

interface SetbackModalProps {
  children?: React.ReactNode;
  currentGrid: MatrixGrid;
  search: string;
  setCurrentGrid: (matrixGrid: MatrixGrid) => void;
}

function SetbackModal({ children, currentGrid, search, setCurrentGrid }: SetbackModalProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm<FormType>();

  const { data: insides } = useQuery(["list-container-inside-types"], () => {
    return jwtAxios.get("/master/list-container-inside-types")
  });

  const { data: containerList, refetch } = useQuery("containers-for-setbacking", () => {
    return jwtAxios.get("/master/containers-for-setbacking", {
      params: {
        name
      }
    })
  });

  useEffect(() => {
    form.resetFields();
  }, [currentGrid]);


  const handleSubmit = async (data: FormType) => {
    setLoading(true);
    jwtAxios.post<MatrixGrid>("/master/container-setbacking", {
      ...data,
      // container_number_list: [data.container_number_list[0]],
      grid_name: currentGrid?.name
    }).then(res => {
      setOpen(false);
      form.resetFields();
      setCurrentGrid(res.data)
      setTimeout(() => {
        queryClient.refetchQueries('container-matrix')
        queryClient.refetchQueries(['grid', currentGrid?.name])
        queryClient.refetchQueries('containers-for-setbacking')
        queryClient.refetchQueries(['container-actions', search])
      }, 300)
    }).catch(error => {
      notification.error({ message: JSON.stringify(error?.response?.data) });
    })
    setLoading(false);
  };


  return (
    <>
      <div onClick={() => setOpen(!!currentGrid)} style={{ border: 40 }}> {children}</div>
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={form.submit}
        confirmLoading={loading}
        width={500}
        okText='Saqlash'
        cancelText='Bekor qilish'
      >
        <div className={styles["add-content"]}>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={console.log}
          >
            <div className={styles.form}>
              <Form.Item
                name={"container_number_list"}
                label="Kontainer raqami"
                rules={[{ required: true, message: "You should enter this field!" }]}
              >
                <Select
                  mode="tags"
                  maxLength={1}
                  placeholder="Kontainer raqami"
                  onChange={(data: string[]) => {
                    form.setFieldValue('numbers', data)
                  }}
                  options={(containerList?.data || []).map(
                    (container: ContainerType) => ({
                      value: container?.number,
                      label: `${container?.number} ${moment(container?.shipping_date).format('YY-MM-DD')}`,
                    })
                  )}
                />
              </Form.Item>

              <Form.Item
                name={"inside"}
                label="Yuk holati"
                rules={[{ required: true, message: "You should enter this field!" }]}
              >
                <Select options={getOptionsfrom(insides?.data)} placeholder='Yuk holati' />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default SetbackModal;
