import { QueryClient, QueryClientProvider } from "react-query";
import AppLayout from "./layout";
import { BrowserRouter } from "react-router-dom";
import JWTAuthAuthProvider from "./auth/JWTAuthProvider";
import { ConfigProvider } from "antd";
import ContainersProvider from "./providers/ContainersProvider/ContainersProvider";

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ContainersProvider>
          <JWTAuthAuthProvider>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#fb3f00",
                },
              }}
            >
              <AppLayout />
            </ConfigProvider>
          </JWTAuthAuthProvider>
        </ContainersProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
