

export enum DandActionsEnum {
    RELOCATING = 'RELOCATING',
    POSITIONING = 'POSITIONING',
    SETBACKING = 'SETBACKING',
    UNLOADING = 'UNLOADING',
    RETURNING = 'RETURNING'
}

export enum DandPrefixEnum {
    GRID = 'GRID',
    C3D = 'C3D',
    CONTROL = 'CONTROL'
}

export function makeDroppableId(action: DandActionsEnum, prefix: DandPrefixEnum, payload: string) {
    return `${action}:${prefix}:${payload}`
}

export function makeDraggableId(action: DandActionsEnum, prefix: DandPrefixEnum, payload: string) {
    return `${action}:${prefix}:${payload}`
}

export type ExtractedDandId = [DandActionsEnum, DandPrefixEnum, string]

export function extractDandId(dandId: string): ExtractedDandId {
    return dandId.split(':') as ExtractedDandId
}