import React from "react";
import styles from "./index.module.scss";
import Grid from "../../Grid";
import Sector from "../../Grid/components/Sector";
import { AreaNames, MatrixGrid } from "../../../shared/types/container";
import { DandActionsEnum } from "../../../helpers/draganddrop";


interface YardProps {
  actions: {
    setCurrentGrid: (matrixGrid: MatrixGrid) => void;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  },
  states: {
    name: AreaNames;
    section: MatrixGrid[][];
    currentGrid: MatrixGrid | null;
    reverse?: boolean;
    dandAction: DandActionsEnum;
    isDragging: boolean;
    search: string;
    filteredYard: boolean;
  }
}


function Yard({ states, actions }: YardProps) {
  const { section, name, reverse, dandAction, currentGrid, isDragging, search, filteredYard } = states;
  const { setCurrentGrid, setIsDragging } = actions
  return (
    <>
      {!reverse ?
        <div className={styles.container}  >
          <div className={styles["row-numbers"]}>
            {section.map((_, index: number) => (
              <div key={index + 1} style={{ background: "white" }}>{index + 1}</div>
            ))}
            <div style={{ background: "white" }}>No</div>
          </div>

          <div className={styles["columns"]}>
            <div className={styles["chunks-container"]}>
              {section.map((cols, i) =>
                cols.map((grid, j) => (
                  <Grid key={j} states={{ grid, dandAction, currentGrid, isDragging, search, filteredYard }} actions={{ setCurrentGrid, setIsDragging }} />
                ))
              )}
            </div>
            <div className={styles["column-numbers"]}>
              {section[0]?.map((_, index: number) => (
                <Sector key={index + 1}>{index + 1}</Sector>
              ))}
            </div>
          </div>
          <div className={styles.name}>{name}</div>
        </div>

        :
        <div className={styles.container}  >
          <div className={styles["row-numbers"]}>
            <div style={{ background: "white" }}>No</div>
            {section.map((_, index: number) => (
              <div key={index + 1} style={{ background: "white" }}>{section.length - index}</div>
            ))}
          </div>

          <div className={styles["columns"]}>
            <div className={styles["column-numbers"]}>
              {section[0]?.map((_, index: number) => (
                <Sector key={index + 1}>{index + 1}</Sector>
              ))}
            </div>
            <div className={styles["chunks-container"]}>
              {section.map((_, i) =>
                section[section.length - i - 1].map((grid, j) => (
                  <Grid key={j} states={{ grid, dandAction, currentGrid, isDragging, search, filteredYard }} actions={{ setCurrentGrid, setIsDragging }} />
                ))
              )}
            </div>
          </div>
          <div className={styles.name}>{name}</div>
        </div>
      }
    </>
  );
}

export default React.memo(Yard);
