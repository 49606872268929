import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo, useRef } from "react";
import jwtAxios from "../../../auth/jwt-api";
import { Button, Input, Table, TableProps } from "antd";
import { initialUrl } from "../../../shared/constants";
import { useQuery } from "react-query";
import { getActions, getColumns } from "./columns";
import {
  addAblePages,
  downloadAblePages,
} from "../../../shared/constants/table";
import { getUrl } from "../../../utils/getApi";
import { changeFields } from "../../../utils/changeFields";
import { downloadModals } from "./downloadModals";

function TablePage() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const page = queryParams.get("page");
  const [s, setS] = useState("");
  const navigate = useNavigate();
  const timeRef = useRef<NodeJS.Timeout>();
  const [pagination, setPagination] = useState<{
    current: number;
    pageSize: number;
    total?: number;
  }>({
    current: 1,
    pageSize: 20,
  });
  const [filters, setFilters] = useState<
    Record<string, (string | number | boolean)[] | null>
  >({});

  const api = getUrl(
    `master/${page}`,
    {
      ...filters,
      page: pagination.current,
      rows: pagination.pageSize,
    },
    s
  );

  // get data
  const { data, refetch, isLoading } = useQuery([api], () => jwtAxios(api), {
    onSuccess(data) {
      setPagination({ ...pagination, total: data.data?.meta?.total });
      data?.data?.data.forEach((el: any, index: number) => {
        el.no =
          pagination.current * pagination.pageSize -
          pagination.pageSize +
          index +
          1;
      });
    },
  });

  // get company types

  const { data: companyTypes } = useQuery(
    ["company-types"],
    () => jwtAxios.get(`master/list-company-types`),
    {
      select(data) {
        return changeFields(data?.data, { code: "value", name: "text" });
      },
    }
  );

  // console.log({ companyTypes });

  const t = (id: string) => id;

  //page changed
  useEffect(() => {
    setS("");
    setPagination({ ...pagination, current: 1 });
    setFilters({});
  }, [page]);

  const editIds = {
    user: "uuid",
  };

  if (!page) {
    navigate(initialUrl);
    return <></>;
  }

  const onChange: TableProps<any>["onChange"] = (
    pagination,
    changedeFilters,
    sorter: any,
    extra
  ) => {
    // console.log("changedeFilters", sorter, changedeFilters);
    let sort = sorter.columnKey;

    switch (sorter.order) {
      case "ascend":
        sort = sort;

        break;
      case "descend":
        sort = `-${sort}`;
        break;

      default:
        sort = "";
        break;
    }
    console.log({ changedeFilters });

    setFilters({ ...filters, ...changedeFilters, sort });
  };

  const columns: any[] = getColumns(t, { company_type: companyTypes })[page as "company"];

  //delete row
  const handleDelete = async (id: string) => {
    try {
      await jwtAxios.delete(`master/${page}/${id}`);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <h1> {t(page)} </h1> <br />
      <Input
        placeholder={t("Search...")}
        onChange={(e) => {
          timeRef.current && clearTimeout(timeRef.current);
          timeRef.current = setTimeout(() => {
            setS(e.target.value);
          }, 1000);
        }}
      // value={s}
      />
      <div
        style={{
          padding: "30px 0",
          display: "flex",
          justifyContent: "flex-end",
          gap: 15,
        }}
      >
        {downloadAblePages.includes(page) && (
          (page in downloadModals) ?
            <>
              {downloadModals[page]}
            </>
            :
            <Link to={""}>
              <Button>Download </Button>
            </Link>
        )}
        {addAblePages.includes(page) && (
          <Link to={`/admin/${page}`}>
            <Button type="primary">Add </Button>
          </Link>
        )}
      </div>
      <Table
        loading={isLoading}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, current: page, pageSize });
          },
        }}
        onChange={onChange}
        dataSource={data?.data?.data || []}
        scroll={{ x: 1000 }}
        columns={
          columns?.concat(
            getActions(t, page, handleDelete, {
              idName: editIds[page as keyof typeof editIds] || "id",
              refetch: refetch,
            })
          ) || []
        }
      />
    </div>
  );
}

export default TablePage;
