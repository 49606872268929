import { MainPropType } from "../../../shared/types";
import styles from './sector.module.scss'

function Sector({ children }: MainPropType) {
    return (
        <div
            className={styles.sector}
            style={{ background: 'white' }}
        >
            {children}
        </div>
    )
}

export default Sector