export const API_URL  = process.env.REACT_APP_API_URL 

export const containerInfo = {
  yard1: {
    columns: 36,
    rows: 10,
    floor: 3,
  },
  yard2: {
    columns: 36,
    rows: 10,
    floor: 4,
  },
};


export const yardCapacity = {
  A: 5,
  B:10 ,
  C: 4,
};


export const initialUrl = "/"