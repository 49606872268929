import { MatrixGrid } from "../../shared/types/container";


export function packGridForDand(grid: MatrixGrid) {
    return `${grid.name}:${grid.containers[0].number}`
}

export function unpackGridForDand(text: string) {
    const [currentGridName, containerNumber] = text.split(':')
    return { currentGridName, containerNumber }
}