import React from "react";
import styles from "./index.module.scss";
function ColumnData({ variable, value }: { variable: string; value: React.ReactNode }) {
  return (
    <div className={styles.row}>
      <div className={styles.variable}>{variable} </div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}

export default ColumnData;
