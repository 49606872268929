
const getOptionsfrom = (
    data?: any[],
    valueField?: string,
    labelField?: string
  ) => {
    return data
      ? data.map((data: any) => ({
          value: data[valueField || "code"],
          label: data[labelField || "name"],
        }))
      : [];
  };


  export default getOptionsfrom