import GridInfo from "../../GridInfo";
import ContainerActions from "../../ContainerActions";
import ContainerSearch from "../../ContainerSearch";
import styles from "./index.module.scss";
import { useContainerState, useContainerActions } from "../../../providers/ContainersProvider/ContainersProvider";
import MovingContainer from "./MovingContainer";
import { Container3D, MatrixGrid } from "../../../shared/types/container";
import React from "react";
import { DandActionsEnum } from "../../../helpers/draganddrop";

export interface ActionBarProps {
  states: {
    currentGrid: MatrixGrid | null;
    dandAction: DandActionsEnum;
    search: string;
    filteredYard: boolean;
  },
  actions: {
    setFilteredYard: React.Dispatch<React.SetStateAction<boolean>>;
    setSearch: (search: string) => void;
    setCurrentGridByContainer: (container: Container3D) => void;
    setCurrentGrid: (matrixGrid: MatrixGrid) => void;
  }
}


function ActionBar({ states, actions }: ActionBarProps) {
  const { currentGrid, dandAction, search, filteredYard } = states;
  const { setSearch, setCurrentGridByContainer, setCurrentGrid, setFilteredYard } = actions;
  return (
    <div className={styles.container}>
      {/* {isMoving && (
        <MovingContainer
          id={currentContainer?.container_id ?? 1}
          setIsMoving={(moving) => dispatch('isMoving', moving)}
        />
      )} */}
      <div className={styles.action_bar}>
        <GridInfo currentGrid={currentGrid} dandAction={dandAction} />
      </div>
      <div className={styles.container_search}>
        <ContainerSearch states={{ search, filteredYard }} actions={{ setSearch, setCurrentGridByContainer, setFilteredYard }} />{" "}
      </div>
      <div className={styles.container_actions}>
        <ContainerActions search={search} currentGrid={currentGrid} setCurrentGrid={setCurrentGrid} />
      </div>
    </div>
  );
}

export default React.memo(ActionBar);
