import React, { useState } from 'react';
import { Upload, Button, message, Form, notification } from 'antd';
import { UploadFile, UploadProps, RcFile } from 'antd/es/upload/interface'
import { UploadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import jwtAxios from '../../../../auth/jwt-api';
import { downloader, downloadWithUrl } from '../helpers';

const FileUpload = () => {
    const [res, setRes] = useState<any>()
    const props: UploadProps<RcFile> = {
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            const isXslx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isXslx) {
                message.error('You can only upload xslx files!');
            }
            return isXslx || Upload.LIST_IGNORE; // Prevent upload if not a .txt file
        },
        customRequest: ({ file, onSuccess, onError }) => {
            const formData = new FormData()
            formData.append('file', file)

            jwtAxios.post(`/master/container-file-upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(res => {
                    setRes(res.data)
                    onSuccess && onSuccess(res.data)
                    message.success(`File uploaded successfully.`);
                })
                .catch(err => {
                    onError && onError(err?.response?.data)
                    message.error(err?.response?.data?.message);
                })
        }
    }

    function handleDownload() {
        downloadWithUrl(`/templates/container_template.xlsx`, 'container_template.xlsx');
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <Button onClick={handleDownload}>Download template</Button>
            </div>
            <Upload {...props}>
                <Button icon={<UploadOutlined />}>Upload XLSX file</Button>
            </Upload>
        </div>
    );
};

export default FileUpload;
