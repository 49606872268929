import { RouteType } from "../../shared/types";
import Admins from "./Admin";
import Companies from "./Company";
import Container from "./Container";
import Permission from "./Permission";
import Role from "./Role";
import TablePage from "./Table";
import ContainerShowPage from './Container/ContainerShowPage';


export const adminRoutes: RouteType[] = [
  {
    id: -1,
    path: "/admin/table",
    element: <TablePage />
  },
  {
    id: 0,
    path: ["/admin/company", "/admin/company/:id"],
    element: <Companies />
  },
  {
    id: 1,
    path: ["/admin/user", "/admin/user/:id"],
    element: <Admins />
  },
  {
    id: 2,
    path: ["/admin/container", "/admin/container/:id"],
    element: <Container />
  },
  {
    id: 3,
    path: "/admin/permission/:id",
    element: <Permission />
  },
  {
    id: 4,
    path: ["/admin/role", "/admin/role/:id"],
    element: <Role />
  },
  {
    id: 5,
    path: ["/admin/container-detail/:id"],
    element: <ContainerShowPage />
  }
]