export const editablePages = [
  "company",
  "container",
  "user",
  "permission",
  "role",
];
export const deleteablePages = [
  "company",
  "container",
  "user",
  "role",
];
export const roleAttachablePages = [
  "user",
];
export const addAblePages = [
  "company",
  "container",
  "user",
  "role",
];
export const downloadAblePages = [
  "container",

];
export const tablePages = [
  "company",
  "container",
  "user",
  "permission",
  "role",
];
