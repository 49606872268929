import { Button, Modal, Select, DatePicker, Spin, notification } from "antd"
import { useState } from "react"
import { useQuery } from "react-query"
import { urlBuilder, downloader } from "../helpers"
import jwtAxios from "../../../../auth/jwt-api"
import { useForm } from "antd/es/form/Form"
import { Form } from "antd"
import styles from './download.module.scss'
import getOptionsfrom from "../../../../utils/getOptionsFrom"
import { AreaType, AreaResponse } from "../types"
import dayjs from "dayjs"
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // This includes isAfter
import { RangeValue } from 'rc-picker/lib/interface'
import { AxiosResponse } from "axios"
import moment from "moment"
import { getColumns } from "../../Table/columns"

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);


type FormType = {
    forwarders: (string | number)[]
    shippers: (string | number)[]
    consignes: (string | number)[]
    area: string | number
    sectors: (string | number)[]
    lines: (string | number)[]
    insides: number[]
    types: number[]
    statuses: number[]
    shipping_date: RangeValue<dayjs.Dayjs> | null
    eta: RangeValue<dayjs.Dayjs> | null
    ata: RangeValue<dayjs.Dayjs> | null
    headers: string[]
}

function ContainerDownloadModal() {
    const [openModal, setOpenModal] = useState(false)
    const [area, setArea] = useState<AreaType>()
    const [form] = useForm<FormType>();
    const [res, setRes] = useState<AxiosResponse>()
    const [processing, setProcessing] = useState(false)

    const forwarderUrl = urlBuilder(`/master/company`, { type: 10 })
    const { data: forwarders } = useQuery(forwarderUrl, () => jwtAxios.get(forwarderUrl))

    const shipperUrl = urlBuilder(`/master/company`, { type: 20 })
    const { data: shippers } = useQuery(shipperUrl, () => jwtAxios.get(shipperUrl))

    const consigneUrl = urlBuilder(`/master/company`, { type: 30 })
    const { data: consignes } = useQuery(consigneUrl, () => jwtAxios.get(consigneUrl))

    const { data: areas } = useQuery(`/master/area`, () => jwtAxios.get<AreaResponse>(`/master/area?pagination=0`))

    const { data: insides } = useQuery(`/master/list-container-inside-types`, () => jwtAxios.get(`master/list-container-inside-types`))
    const { data: types } = useQuery(`/master/list-container-types`, () => jwtAxios.get(`master/list-container-types`))
    const { data: statuses } = useQuery(`/master/list-container-status-types`, () => jwtAxios.get(`master/list-container-status-types`))
    const { data: headers } = useQuery(`/master/container-report-headers`, () => jwtAxios.get(`master/container-report-headers`))


    function handleSubmit(data: FormType) {
        setProcessing(true)
        jwtAxios.post(`/master/container-report`, data, { responseType: 'blob' })
            .then(res => {
                // console.log(res.data);
                notification.success({ message: 'Your report is ready!' })
                downloader(res, moment(Date.now()).format("DD_MM_YYYY__HH_mm") + '_containers_report.xlsx')
                setProcessing(false)
                // setRes(res)
            })
            .catch(err => {
                setProcessing(false)
                // setRes(err)
            })
        // if (res) {
        //     // downloader(res, moment(Date.now()).format("DD_MM_YYYY__HH_mm") + '_containers_report.xlsx')
        //     setRes(undefined)
        // } else {
        // }
    }

    return (
        <>
            <Modal open={openModal}
                onCancel={() => {
                    setOpenModal(false)
                    setArea(undefined)
                    setProcessing(false)
                    setRes(undefined)
                    form.resetFields()
                }}
                okText={processing ? 'Processing' : 'Download'}
                onOk={form.submit}
                confirmLoading={processing}
                width={800}>
                <div style={{ padding: '20px 0' }}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={handleSubmit}
                        onFinishFailed={console.log}>
                        <div className={styles.companies}>
                            <Form.Item
                                name={'statuses'}
                                label={
                                    <span className={styles.required}>Holati</span>
                                }>
                                <Select
                                    mode="tags"
                                    maxLength={1}
                                    placeholder="Holati"
                                    onChange={(data: any) => form.setFieldValue('statuses', data)}
                                    options={getOptionsfrom(statuses?.data)}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'insides'}
                                label={
                                    <span className={styles.required}>Yuk holati</span>
                                }>
                                <Select
                                    mode="multiple"
                                    maxLength={1}
                                    placeholder="Yuk holati"
                                    onChange={(data: any) => form.setFieldValue('insides', data)}
                                    options={getOptionsfrom(insides?.data)}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'types'}
                                label={
                                    <span className={styles.required}>Turi</span>
                                }>
                                <Select
                                    mode="tags"
                                    maxLength={1}
                                    placeholder="Turi"
                                    onChange={(data: any) => form.setFieldValue('types', data)}
                                    options={getOptionsfrom(types?.data)}
                                />
                            </Form.Item>
                        </div>

                        <div className={styles.companies}>
                            <Form.Item
                                name={'forwarders'}
                                label={
                                    <span className={styles.required}>Forwarders</span>
                                }>
                                <Select
                                    mode="multiple"
                                    maxLength={1}
                                    placeholder="Forwarders"
                                    onChange={(data: any) => form.setFieldValue('forwarders', data)}
                                    options={getOptionsfrom(forwarders?.data?.data, 'id', 'name')}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'shippers'}
                                label={
                                    <span className={styles.required}>Shippers</span>
                                }>
                                <Select
                                    mode="tags"
                                    maxLength={1}
                                    placeholder="Shippers"
                                    onChange={(data: any) => form.setFieldValue('shippers', data)}
                                    options={getOptionsfrom(shippers?.data?.data, 'id', 'name')}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'consignes'}
                                label={
                                    <span className={styles.required}>Consignes</span>
                                }>
                                <Select
                                    mode="tags"
                                    maxLength={1}
                                    placeholder="Consignes"
                                    onChange={(data: any) => form.setFieldValue('consignes', data)}
                                    options={getOptionsfrom(consignes?.data?.data, 'id', 'name')}
                                />
                            </Form.Item>
                        </div>

                        <div className={styles.areas}>
                            <Form.Item
                                name={'area'}
                                label={
                                    <span className={styles.required}>Areas</span>
                                }>
                                <Select
                                    mode="tags"
                                    maxLength={1}
                                    placeholder="Areas"
                                    onChange={(data: any[]) => {
                                        if (data.length > 1) {
                                            form.setFieldValue('area', data[1])
                                            if (data) {
                                                form.setFieldValue('sectors', [])
                                                form.setFieldValue('lines', [])
                                                let area = areas?.data?.data.find(a => a.name == data[1])
                                                setArea(area)
                                            }
                                        } else {
                                            form.setFieldValue('area', data[0])
                                            if (data) {
                                                form.setFieldValue('sectors', [])
                                                form.setFieldValue('lines', [])
                                                let area = areas?.data?.data.find(a => a.name == data[0])
                                                setArea(area)
                                            }
                                        }
                                    }}
                                    options={getOptionsfrom(areas?.data?.data, 'name', 'name')}
                                />
                            </Form.Item>
                            {area && <Form.Item
                                name={'sectors'}
                                label={
                                    <span className={styles.required}>Sectors (x={area.x})</span>
                                }>
                                <Select
                                    mode="tags"
                                    maxLength={1}
                                    placeholder="Sectors"
                                    onChange={(data: any[]) => {
                                        form.setFieldValue('sectors', data)
                                    }}
                                    options={(Array.from({ length: area.x }, (_, i) => ({ value: i + 1, label: i + 1 })))}
                                />
                            </Form.Item>}
                            {area && <Form.Item
                                name={'lines'}
                                label={
                                    <span className={styles.required}>Liniya (y={area.y})</span>
                                }>
                                <Select
                                    mode="tags"
                                    maxLength={1}
                                    placeholder="Lines"
                                    onChange={(data: any[]) => {
                                        form.setFieldValue('lines', data)
                                    }}
                                    options={(Array.from({ length: area.y }, (_, i) => ({ value: i + 1, label: i + 1 })))}
                                />
                            </Form.Item>}
                        </div>
                        <div className={styles.dates}>
                            <Form.Item
                                name={'shipping_date'}
                                label={
                                    <span className={styles.required}>Shipping date</span>
                                }>
                                <DatePicker.RangePicker
                                    style={{ marginBottom: 8 }}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'eta'}
                                label={
                                    <span className={styles.required}>Eta</span>
                                }>
                                <DatePicker.RangePicker
                                    style={{ marginBottom: 8 }}

                                />
                            </Form.Item>
                            <Form.Item
                                name={'ata'}
                                label={
                                    <span className={styles.required}>Ata</span>
                                }>
                                <DatePicker.RangePicker
                                    style={{ marginBottom: 8 }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name={'headers'}
                                label={
                                    <span className={styles.required}>Excel headers</span>
                                }>
                                <Select
                                    mode="tags"
                                    maxLength={1}
                                    placeholder="Headers"
                                    onChange={(data: string[]) => {
                                        form.setFieldValue('headers', data)
                                    }}
                                    options={getOptionsfrom(headers?.data, 'value', 'key')}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Modal>

            <Button onClick={() => setOpenModal(!openModal)}>
                Download
            </Button>
        </>
    )
}

export default ContainerDownloadModal