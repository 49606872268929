import { createContext, useContext, useEffect, useState } from "react";
import jwtAxios, { setAuthToken } from "./jwt-api";
import { MainPropType, UserType } from "../shared/types";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { AxiosResponse } from "axios";
import { useContainerActions } from "../providers/ContainersProvider/ContainersProvider";
// import { useContainersActions } from "../utils/ContainerProvider";

export type UserDataType = {
  user?: UserType | null;
  isAuthenticated: boolean;
  isLoading: boolean;
};

export type UserActionsType = {
  signUpUser: (x: {
    name: string;
    email: string;
    password: string;
  }) => Promise<void>;
  signInUser: (x: { email: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
};

const JWTAuthContext = createContext<UserDataType>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});

const JWTAuthActionsContext = createContext<any>({});

export const useAuthData = () => useContext(JWTAuthContext);
export const useAuthAction = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }: MainPropType) => {
  const [userData, setUserData] = useState<{
    user: UserType | null;
    isAuthenticated: boolean;
    isLoading: boolean;
  }>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const { fetchContainers } = useContainerActions()
  // const fetchContainers = useContainersActions(
  //   (state) => state.fetchContainers
  // );

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setUserData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get("/master/user-profile")
        .then(({ data }: AxiosResponse<any, any>) => {
 
          setUserData({
            user: data.data,
            isLoading: false,
            isAuthenticated: true,
          });
          fetchContainers();
        })
        .catch(() =>
          setUserData({
            user: null,
            isLoading: false,
            isAuthenticated: false,
          })
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({
    username,
    password,
    rememberMe,
  }: {
    username: string;
    password: string;
    rememberMe: boolean;
  }) => {
    try {
      setUserData((prev) => ({ ...prev, isLoading: true }));

      const { data } = await jwtAxios.post("auth/login", {
        grant_type: "password",
        client_secret: "Mt57LfRyUwwWIuKfSXnNzQAeWxQY0JFNerkrLymd",
        client_id: 2,
        username,
        password,
      });

      setAuthToken(data.access_token, rememberMe);
      const res = await jwtAxios.get("/master/user-profile");

      setUserData({
        user: res.data.data,
        isAuthenticated: true,
        isLoading: false,
      });

      navigate("/");
      fetchContainers();
      api.success({
        message: "You successfully logged in!",
      });
    } catch (error: any) {
      api.error({
        message: "Username or password is incorrect!",
      });
      setUserData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const logout = async () => {
    setAuthToken();
    setUserData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    api.success({
      message: "You successfully logged out!",
    });
    navigate("/");
  };

  return (
    <>
      {contextHolder}
      <JWTAuthContext.Provider
        value={{
          ...userData,
        }}
      >
        <JWTAuthActionsContext.Provider
          value={{
            signInUser,
            logout,
            setUserData,
          }}
        >
          {children}
        </JWTAuthActionsContext.Provider>
      </JWTAuthContext.Provider>
    </>
  );
};
export default JWTAuthAuthProvider;
