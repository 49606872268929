import { useState } from "react"
import { colors } from "../Grid/components/constants";
import { ContainerType, MatrixContainer } from "../../shared/types/container";

export interface C3DSvgImageProps {
    container?: ContainerType | MatrixContainer;
    color?: string;
    width?: string | number;
    height?: string | number;
}


function C3DSvgImage({ color, width, height, container }: C3DSvgImageProps) {
    const [w, setW] = useState(328);
    const [h, setH] = useState(140);
    const [x, setX] = useState(1070);
    const [y, setY] = useState(455);

    function fill() {
        if (color) return color;
        return container ? `var(--color-${colors[container.z_axis - 1]})` : `rgba(255,255,255,0.01)`
    }
    return (
        <svg width={width ?? w} height={height ?? h} viewBox={`0 0 1070 455`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_26_1903)">
                <path d="M916 73.8435L1062 5V369.671L916 445V73.8435Z" fill={fill()} />
                <g filter="url(#filter0_d_26_1903)">
                    <path d="M916 74H4V445H916V74Z" fill={fill()} />
                </g>
                <g filter="url(#filter1_d_26_1903)">
                    <path d="M188 2C188 -0.763527 184.912 -0.5 179.5 -0.5L167 -0.499953L153 3.50005C148 5.90571 148.5 6.61325 148.5 9.50005L150 17C151.5 19.8868 151.088 24 156.5 24L170 21.1133L180.5 20.151C185.912 20.151 189 18.368 189.5 15L188 2Z" fill={fill()} />
                </g>
                <path d="M178 13C180.762 13 183 10.7615 183 8C183 5.23854 180.762 3 178 3C175.238 3 173 5.23854 173 8C173 10.7615 175.238 13 178 13Z" fill="black" fillOpacity="0.4" />
                <path d="M153.365 13.0907C154.126 15.7931 155.545 17.4617 159.127 16.8868C162.708 16.3119 163.497 13.8953 162.735 11.1928C161.974 8.49033 159.949 6.52541 156.367 7.10042C152.786 7.67534 152.603 10.3883 153.365 13.0907Z" fill="black" fillOpacity="0.4" />
                <path d="M167.773 6H1063L916 74H4L167.773 6Z" fill={fill()} />
                <path d="M907 66L1015.24 15.8884L1023 15.9977L915 66H907Z" fill="black" fillOpacity="0.25" />
                <path d="M898 91H25V430H898V91Z" fill="white" fillOpacity="0.3" />
                <g filter="url(#filter2_d_26_1903)">
                    <path d="M47 91H35V430H47V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter3_d_26_1903)">
                    <path d="M71 91H59V430H71V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter4_d_26_1903)">
                    <path d="M95 91H83V430H95V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter5_d_26_1903)">
                    <path d="M119 91H107V430H119V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter6_d_26_1903)">
                    <path d="M143 91H131V430H143V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter7_d_26_1903)">
                    <path d="M167 91H155V430H167V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter8_d_26_1903)">
                    <path d="M191 91H179V430H191V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter9_d_26_1903)">
                    <path d="M215 91H203V430H215V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter10_d_26_1903)">
                    <path d="M239 91H227V430H239V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter11_d_26_1903)">
                    <path d="M263 91H251V430H263V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter12_d_26_1903)">
                    <path d="M287 91H275V430H287V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter13_d_26_1903)">
                    <path d="M311 91H299V430H311V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter14_d_26_1903)">
                    <path d="M335 91H323V430H335V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter15_d_26_1903)">
                    <path d="M359 91H347V430H359V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter16_d_26_1903)">
                    <path d="M383 91H371V430H383V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter17_d_26_1903)">
                    <path d="M407 91H395V430H407V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter18_d_26_1903)">
                    <path d="M431 91H419V430H431V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter19_d_26_1903)">
                    <path d="M455 91H443V430H455V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter20_d_26_1903)">
                    <path d="M479 91H467V430H479V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter21_d_26_1903)">
                    <path d="M503 91H491V430H503V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter22_d_26_1903)">
                    <path d="M527 91H515V430H527V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter23_d_26_1903)">
                    <path d="M551 91H539V430H551V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter24_d_26_1903)">
                    <path d="M575 91H563V430H575V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter25_d_26_1903)">
                    <path d="M599 91H587V430H599V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter26_d_26_1903)">
                    <path d="M623 91H611V430H623V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter27_d_26_1903)">
                    <path d="M647 91H635V430H647V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter28_d_26_1903)">
                    <path d="M671 91H659V430H671V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter29_d_26_1903)">
                    <path d="M695 91H683V430H695V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter30_d_26_1903)">
                    <path d="M719 91H707V430H719V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter31_d_26_1903)">
                    <path d="M743 91H731V430H743V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter32_d_26_1903)">
                    <path d="M767 91H755V430H767V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter33_d_26_1903)">
                    <path d="M791 91H779V430H791V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter34_d_26_1903)">
                    <path d="M815 91H803V430H815V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter35_d_26_1903)">
                    <path d="M839 91H827V430H839V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter36_d_26_1903)">
                    <path d="M863 91H851V430H863V91Z" fill={fill()} />
                </g>
                <g filter="url(#filter37_d_26_1903)">
                    <path d="M887 91H875V430H887V91Z" fill={fill()} />
                </g>
                <path d="M898 425H25V430H898V425Z" fill="black" fillOpacity="0.3" />
                <path d="M913 77.0557L919 76.9709V438.914H913V77.0557Z" fill="white" fillOpacity="0.2" />
                <path d="M14 76L23 71H914L915.5 76H14Z" fill="white" fillOpacity="0.2" />
                <g filter="url(#filter38_d_26_1903)">
                    <path d="M20.8 67H5.2C2.3281 67 0 69.2385 0 72V87C0 89.7615 2.3281 92 5.2 92H20.8C23.6719 92 26 89.7615 26 87V72C26 69.2385 23.6719 67 20.8 67Z" fill={fill()} />
                </g>
                <path d="M930 85.024L935 83V419.368L930 422V85.024Z" fill="black" fillOpacity="0.3" />
                <path d="M1046 28.5284L1051 26V363.035L1046 366V28.5284Z" fill="black" fillOpacity="0.4" />
                <path d="M915.5 76L914 70.0856L1053.69 6.93237L1056.19 12.3093L915.5 76Z" fill="white" fillOpacity="0.2" />
                <path d="M934.79 81.685L1046 28.5588V32.9706L935 86.4999L934.79 81.685Z" fill="black" fillOpacity="0.4" />
                <path d="M25 91L25 431H30L30 91H25Z" fill="black" fillOpacity="0.3" />
                <path d="M935 414.581L1046 361.5V366L935.028 419.269L935 414.581Z" fill="black" fillOpacity="0.4" />
                <g filter="url(#filter39_d_26_1903)">
                    <path d="M20.8 425H5.2C2.3281 425 0 427.238 0 430V445C0 447.762 2.3281 450 5.2 450H20.8C23.6719 450 26 447.762 26 445V430C26 427.238 23.6719 425 20.8 425Z" fill={fill()} />
                </g>
                <path d="M12.5 86C16.0899 86 19 83.09 19 79.5C19 75.91 16.0899 73 12.5 73C8.9101 73 6 75.91 6 79.5C6 83.09 8.9101 86 12.5 86Z" fill="black" fillOpacity="0.4" />
                <path d="M13.5 444C17.0899 444 20 441.09 20 437.5C20 433.91 17.0899 431 13.5 431C9.9101 431 7 433.91 7 437.5C7 441.09 9.9101 444 13.5 444Z" fill="black" fillOpacity="0.4" />
                <path d="M898 91H25V97H898V91Z" fill="black" fillOpacity="0.3" />
                <path d="M169 11H1036L1023.01 16H170L169 11Z" fill="black" fillOpacity="0.3" />
                <path d="M41 66H915L911.289 67H36L41 66Z" fill="black" fillOpacity="0.25" />
                <path d="M41 66L169 11L170 16L53 66H41Z" fill="black" fillOpacity="0.3" />
                <path d="M892 91V431H897V91H892Z" fill="black" fillOpacity="0.3" />
                <path d="M943.97 99.354L944.54 106.824L921.532 113.997L920.962 106.361L943.97 99.354Z" fill="black" fillOpacity="0.25" />
                <path d="M1058.62 51.2975L1058.98 58.2682L1039.7 66.01L1039.35 59.1596L1058.62 51.2975Z" fill="black" fillOpacity="0.4" />
                <path d="M1058.62 343.298L1058.98 350.268L1039.7 358.01L1039.35 351.16L1058.62 343.298Z" fill="black" fillOpacity="0.4" />
                <path d="M1058.62 274.298L1058.98 281.268L1039.7 289.01L1039.35 282.16L1058.62 274.298Z" fill="black" fillOpacity="0.4" />
                <path d="M946.75 64.023L945.5 59.4235L963.05 53.1641L964 57.5922L946.75 64.023Z" fill="url(#paint0_radial_26_1903)" />
                <path d="M962 254L960.5 253.071L960 251.679V249.821L983 241V246.107L962 254Z" fill="url(#paint1_linear_26_1903)" />
                <path d="M1011.01 236L1010.6 231.077L1028.6 224.152L1030 224.473L1030.5 225.395L1031 226.317V227.7L1011.01 236Z" fill="url(#paint2_linear_26_1903)" />
                <path d="M1037 225.5V220.132L1044 217L1044.68 217.437L1045.21 218.519L1045.73 219.603L1046 220.579L1037 225.5Z" fill="url(#paint3_linear_26_1903)" />
                <path d="M937.5 265L936.5 264.083L935.5 261.792L935.588 260.311L952.01 254V259.042L937.5 265Z" fill="url(#paint4_linear_26_1903)" />
                <path d="M945.9 427.98L944.64 423.381L962.19 417.121L963.14 421.549L945.9 427.98Z" fill="url(#paint5_radial_26_1903)" />
                <path d="M978 412.121L976.66 407.533L994.09 400.98L995.12 405.401L978 412.121Z" fill="url(#paint6_radial_26_1903)" />
                <path d="M1001.11 399.26L999.68 394.685L1016.98 387.84L1018.1 392.253L1001.11 399.26Z" fill="url(#paint7_radial_26_1903)" />
                <path d="M1028.36 386.458L1027.07 382.908L1042.68 375.62L1043.68 379.072L1028.36 386.458Z" fill="url(#paint8_radial_26_1903)" />
                <path d="M976.9 48.9798L975.64 44.3808L993.19 38.1213L994.14 42.5494L976.9 48.9798Z" fill="url(#paint9_radial_26_1903)" />
                <path d="M965.52 241.005L966.52 240.104L967.52 240.103L968.52 240.101L969.52 240.1L970.52 240.998L970.5 258.085L969.49 258.986L968.49 258.987L967.49 258.988L966.49 258.99L965.5 258.092L965.52 241.005Z" fill="url(#paint10_radial_26_1903)" />
                <g filter="url(#filter40_d_26_1903)">
                    <path d="M1062.2 363H1050.8C1048.7 363 1047 364.701 1047 366.8V378.2C1047 380.299 1048.7 382 1050.8 382H1062.2C1064.3 382 1066 380.299 1066 378.2V366.8C1066 364.701 1064.3 363 1062.2 363Z" fill={fill()} />
                </g>
                <g filter="url(#filter41_d_26_1903)">
                    <path d="M1062.2 0H1050.8C1048.7 0 1047 1.70124 1047 3.80001V15.2C1047 17.2988 1048.7 19 1050.8 19H1062.2C1064.3 19 1066 17.2988 1066 15.2V3.80001C1066 1.70124 1064.3 0 1062.2 0Z" fill={fill()} />
                </g>
                <path d="M1022.45 220.834L1023.32 220.09L1024.19 220.088L1025.05 220.086L1025.92 220.084L1026.79 220.824L1026.75 234.928L1025.88 235.672L1025.01 235.674L1024.15 235.676L1023.28 235.678L1022.41 234.937L1022.45 220.834Z" fill="url(#paint11_radial_26_1903)" />
                <path d="M1040.36 213.72L1041.05 213.071L1041.73 213.072H1042.42H1043.1L1043.79 213.721V226.049L1043.11 226.697H1042.42L1041.74 226.696H1041.05L1040.36 226.047V213.72Z" fill="url(#paint12_radial_26_1903)" />
                <path d="M939 251.952L940 251H941H942H943L944 251.952V270.048L943 271H942H941H940L939 270.048V251.952Z" fill="url(#paint13_radial_26_1903)" />
                <path d="M1000.9 37.9798L999.64 33.3808L1017.19 27.1213L1018.14 31.5494L1000.9 37.9798Z" fill="url(#paint14_radial_26_1903)" />
                <path d="M1027.48 25.7488L1026.4 21.7143L1042.81 15.9081L1043.6 19.7968L1027.48 25.7488Z" fill="url(#paint15_radial_26_1903)" />
                <path d="M1058.62 168.298L1058.98 175.268L1039.7 183.01L1039.35 176.16L1058.62 168.298Z" fill="black" fillOpacity="0.4" />
                <path d="M942.97 307.354L943.54 314.824L920.532 321.997L919.962 314.361L942.97 307.354Z" fill="black" fillOpacity="0.4" />
                <path d="M943.97 203.354L944.54 210.824L921.532 217.997L920.962 210.361L943.97 203.354Z" fill="black" fillOpacity="0.4" />
                <path d="M943.97 396.354L944.54 403.824L921.532 410.997L920.962 403.361L943.97 396.354Z" fill="black" fillOpacity="0.4" />
                <path d="M990.6 133H980.4C978.52 133 977 134.432 977 136.2V145.8C977 147.568 978.52 149 980.4 149H990.6C992.48 149 994 147.568 994 145.8V136.2C994 134.432 992.48 133 990.6 133Z" fill="url(#paint16_linear_26_1903)" />
                <path d="M959.6 142H949.4C947.52 142 946 143.432 946 145.2V154.8C946 156.568 947.52 158 949.4 158H959.6C961.48 158 963 156.568 963 154.8V145.2C963 143.432 961.48 142 959.6 142Z" fill="url(#paint17_linear_26_1903)" />
                <path d="M959.6 367H949.4C947.52 367 946 368.432 946 370.2V379.8C946 381.568 947.52 383 949.4 383H959.6C961.48 383 963 381.568 963 379.8V370.2C963 368.432 961.48 367 959.6 367Z" fill="url(#paint18_linear_26_1903)" />
                <path d="M990.6 349H980.4C978.52 349 977 350.432 977 352.2V361.8C977 363.568 978.52 365 980.4 365H990.6C992.48 365 994 363.568 994 361.8V352.2C994 350.432 992.48 349 990.6 349Z" fill="url(#paint19_linear_26_1903)" />
                <path d="M1013.6 334H1003.4C1001.52 334 1000 335.432 1000 337.2V346.8C1000 348.568 1001.52 350 1003.4 350H1013.6C1015.48 350 1017 348.568 1017 346.8V337.2C1017 335.432 1015.48 334 1013.6 334Z" fill="url(#paint20_linear_26_1903)" />
                <path d="M1039.6 320H1029.4C1027.52 320 1026 321.432 1026 323.2V332.8C1026 334.568 1027.52 336 1029.4 336H1039.6C1041.48 336 1043 334.568 1043 332.8V323.2C1043 321.432 1041.48 320 1039.6 320Z" fill="url(#paint21_linear_26_1903)" />
                <path d="M1040.6 119H1030.4C1028.52 119 1027 120.432 1027 122.2V131.8C1027 133.568 1028.52 135 1030.4 135H1040.6C1042.48 135 1044 133.568 1044 131.8V122.2C1044 120.432 1042.48 119 1040.6 119Z" fill="url(#paint22_linear_26_1903)" />
                <path d="M1013.6 126H1003.4C1001.52 126 1000 127.432 1000 129.2V138.8C1000 140.568 1001.52 142 1003.4 142H1013.6C1015.48 142 1017 140.568 1017 138.8V129.2C1017 127.432 1015.48 126 1013.6 126Z" fill="url(#paint23_linear_26_1903)" />
                <path d="M952 57.0998L957 54.7646V423.331L952 425.82V57.0998Z" fill="url(#paint24_radial_26_1903)" />
                <path d="M983 41.9179L988 39.5V407.252L983 409.855V41.9179Z" fill="url(#paint25_radial_26_1903)" />
                <path d="M1006 31.3715L1011 29V395.364L1006 397.936V31.3715Z" fill="url(#paint26_radial_26_1903)" />
                <path d="M1033 19.7603L1037 18V382.429L1033 384.5V19.7603Z" fill="url(#paint27_radial_26_1903)" />
                <g filter="url(#filter42_d_26_1903)">
                    <path d="M938.61 85.6141C941.11 84.1631 945.35 83.068 945 80.3788C944 72.64 943 69.7379 940.5 66.267C938 62.7954 938 61.0317 933.5 62.4827C931.588 63.0993 925.373 66.0049 924.61 66.267C922.67 66.9316 918.11 66.4479 918.11 66.4479C918.11 66.4479 899.61 66.267 898.11 66.267C896.61 66.267 893.11 67.7181 893.11 71.1038C893.11 74.4895 893.11 80.7773 893.11 86.5815C893.11 89.4835 896.703 91.2848 898.108 91.4182C899.513 91.5508 919.11 91.4182 919.11 91.4182C922.61 91.9019 925.201 91.3979 927.17 90.4509C932.533 87.869 936.11 87.0652 938.61 85.6141Z" fill={fill()} />
                </g>
                <g filter="url(#filter43_d_26_1903)">
                    <path d="M937.5 445.187C940 444.222 942.05 442.046 941.5 439.393C941 436.979 939 425.305 938 423.941C937 422.577 934.5 421.527 930 422.975C928.088 423.59 919.873 425.043 919.11 425.305C917.17 425.968 918.11 425.485 918.11 425.485C918.11 425.485 899.61 425.305 898.11 425.305C896.61 425.305 893.11 426.753 893.11 430.133C893.11 433.514 893.11 439.791 893.11 445.585C893.11 448.483 896.703 450.281 898.108 450.414C899.513 450.546 919.11 450.414 919.11 450.414C923 449.533 921 450.016 924.5 449.05C925.5 448.775 935 446.153 937.5 445.187Z" fill={fill()} />
                </g>
                <path d="M907 85C910.314 85 913 82.3138 913 79C913 75.6862 910.314 73 907 73C903.686 73 901 75.6862 901 79C901 82.3138 903.686 85 907 85Z" fill="black" fillOpacity="0.4" />
                <path d="M1057 14C1059.76 14 1062 11.7615 1062 9C1062 6.23846 1059.76 4 1057 4C1054.24 4 1052 6.23846 1052 9C1052 11.7615 1054.24 14 1057 14Z" fill="black" fillOpacity="0.4" />
                <path d="M1057 378C1059.76 378 1062 375.762 1062 373C1062 370.238 1059.76 368 1057 368C1054.24 368 1052 370.238 1052 373C1052 375.762 1054.24 378 1057 378Z" fill="black" fillOpacity="0.4" />
                <path d="M905.5 444C909.09 444 912 441.314 912 438C912 434.686 909.09 432 905.5 432C901.91 432 899 434.686 899 438C899 441.314 901.91 444 905.5 444Z" fill="black" fillOpacity="0.4" />
                <path d="M932.149 440.803C935.621 439.963 936.411 436.82 935.5 433.616C934.589 430.411 932.324 428.356 928.851 429.197C925.379 430.037 924.089 433.18 925 436.385C925.91 439.589 928.676 441.644 932.149 440.803Z" fill="black" fillOpacity="0.4" />
                <path d="M925.552 77.8633C926.752 80.9844 929.525 82.9265 932.908 81.8194C936.292 80.7123 936.648 77.7462 935.448 74.625C934.248 71.5039 931.946 69.4078 928.563 70.5149C925.18 71.622 924.352 74.7422 925.552 77.8633Z" fill="black" fillOpacity="0.4" />
            </g>
            <defs>
                <filter id="filter0_d_26_1903" x="0" y="74" width="920" height="379" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter1_d_26_1903" x="147.486" y="-0.506714" width="49.0139" height="32.5067" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter2_d_26_1903" x="35" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter3_d_26_1903" x="59" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter4_d_26_1903" x="83" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter5_d_26_1903" x="107" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter6_d_26_1903" x="131" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter7_d_26_1903" x="155" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter8_d_26_1903" x="179" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter9_d_26_1903" x="203" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter10_d_26_1903" x="227" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter11_d_26_1903" x="251" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter12_d_26_1903" x="275" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter13_d_26_1903" x="299" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter14_d_26_1903" x="323" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter15_d_26_1903" x="347" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter16_d_26_1903" x="371" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter17_d_26_1903" x="395" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter18_d_26_1903" x="419" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter19_d_26_1903" x="443" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter20_d_26_1903" x="467" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter21_d_26_1903" x="491" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter22_d_26_1903" x="515" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter23_d_26_1903" x="539" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter24_d_26_1903" x="563" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter25_d_26_1903" x="587" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter26_d_26_1903" x="611" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter27_d_26_1903" x="635" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter28_d_26_1903" x="659" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter29_d_26_1903" x="683" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter30_d_26_1903" x="707" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter31_d_26_1903" x="731" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter32_d_26_1903" x="755" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter33_d_26_1903" x="779" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter34_d_26_1903" x="803" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter35_d_26_1903" x="827" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter36_d_26_1903" x="851" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter37_d_26_1903" x="875" y="91" width="16" height="339" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter38_d_26_1903" x="-1" y="67" width="34" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter39_d_26_1903" x="-1" y="417" width="34" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="-4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter40_d_26_1903" x="1040" y="358" width="27" height="27" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-3" dy="-1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter41_d_26_1903" x="1040" y="-2" width="27" height="27" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-3" dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter42_d_26_1903" x="887.11" y="61.9727" width="59.9104" height="37.6322" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <filter id="filter43_d_26_1903" x="889.11" y="415.279" width="56.48" height="36.1943" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="-3" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_1903" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_1903" result="shape" />
                </filter>
                <radialGradient id="paint0_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(953.67 56.758) rotate(-20.0024) scale(9.71385 2.31485)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <linearGradient id="paint1_linear_26_1903" x1="958.45" y1="250.427" x2="982.31" y2="240.212" gradientUnits="userSpaceOnUse">
                    <stop offset="0.13" stopColor="#C8BBBB" />
                    <stop offset="0.42" stopColor="#ACA3A3" />
                    <stop offset="1" stopColor="#737373" />
                </linearGradient>
                <linearGradient id="paint2_linear_26_1903" x1="1009.39" y1="231.555" x2="1028.04" y2="223.512" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#737373" />
                    <stop offset="0.205" stopColor="#ACA3A3" />
                    <stop offset="0.955" stopColor="#C8BBBB" />
                </linearGradient>
                <linearGradient id="paint3_linear_26_1903" x1="1035.9" y1="220.017" x2="1043.65" y2="216.572" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#737373" />
                    <stop offset="0.205" stopColor="#ACA3A3" />
                    <stop offset="0.955" stopColor="#C8BBBB" />
                </linearGradient>
                <linearGradient id="paint4_linear_26_1903" x1="934.481" y1="260.748" x2="951.489" y2="253.373" gradientUnits="userSpaceOnUse">
                    <stop offset="0.13" stopColor="#C8BBBB" />
                    <stop offset="0.42" stopColor="#ACA3A3" />
                    <stop offset="1" stopColor="#737373" />
                </linearGradient>
                <radialGradient id="paint5_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(952.81 420.715) rotate(-20.0024) scale(9.71386 2.31485)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint6_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(984.78 404.73) rotate(-20.9808) scale(9.70669 2.3215)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint7_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1007.74 391.747) rotate(-21.9615) scale(9.69949 2.32791)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint8_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1034.35 379.716) rotate(-25.3525) scale(8.97896 1.83121)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint9_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(983.81 41.7151) rotate(-20.0023) scale(9.71385 2.31484)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint10_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(969.57 249.417) rotate(88.4173) scale(8.83376 2.49992)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint11_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1025.95 227.775) rotate(88.4005) scale(7.29129 2.16698)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint12_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1043.15 219.794) rotate(88.3865) scale(6.37332 1.71433)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint13_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(943.06 260.867) rotate(88.4217) scale(9.3553 2.49991)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint14_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1007.81 30.7151) rotate(-20.0023) scale(9.71385 2.31484)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint15_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1034.03 19.2302) rotate(-19.843) scale(9.07073 2.02662)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <linearGradient id="paint16_linear_26_1903" x1="985.5" y1="133" x2="985.5" y2="149" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9D9090" />
                    <stop offset="0.5" stopColor="#B49D9D" />
                    <stop offset="1" stopColor="#9D9090" />
                </linearGradient>
                <linearGradient id="paint17_linear_26_1903" x1="954.5" y1="142" x2="954.5" y2="158" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9D9090" />
                    <stop offset="0.5" stopColor="#B49D9D" />
                    <stop offset="1" stopColor="#9D9090" />
                </linearGradient>
                <linearGradient id="paint18_linear_26_1903" x1="954.5" y1="367" x2="954.5" y2="383" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9D9090" />
                    <stop offset="0.5" stopColor="#B49D9D" />
                    <stop offset="1" stopColor="#9D9090" />
                </linearGradient>
                <linearGradient id="paint19_linear_26_1903" x1="985.5" y1="349" x2="985.5" y2="365" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9D9090" />
                    <stop offset="0.5" stopColor="#B49D9D" />
                    <stop offset="1" stopColor="#9D9090" />
                </linearGradient>
                <linearGradient id="paint20_linear_26_1903" x1="1008.5" y1="334" x2="1008.5" y2="350" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9D9090" />
                    <stop offset="0.5" stopColor="#B49D9D" />
                    <stop offset="1" stopColor="#9D9090" />
                </linearGradient>
                <linearGradient id="paint21_linear_26_1903" x1="1034.5" y1="320" x2="1034.5" y2="336" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9D9090" />
                    <stop offset="0.5" stopColor="#B49D9D" />
                    <stop offset="1" stopColor="#9D9090" />
                </linearGradient>
                <linearGradient id="paint22_linear_26_1903" x1="1035.5" y1="119" x2="1035.5" y2="135" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9D9090" />
                    <stop offset="0.5" stopColor="#B49D9D" />
                    <stop offset="1" stopColor="#9D9090" />
                </linearGradient>
                <linearGradient id="paint23_linear_26_1903" x1="1008.5" y1="126" x2="1008.5" y2="142" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9D9090" />
                    <stop offset="0.5" stopColor="#B49D9D" />
                    <stop offset="1" stopColor="#9D9090" />
                </linearGradient>
                <radialGradient id="paint24_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(954.5 241.648) rotate(90) scale(184.351 2.5)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint25_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(985.5 225.928) rotate(90) scale(183.075 2.5)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint26_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1008.5 214.821) rotate(90) scale(183.178 2.5)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <radialGradient id="paint27_radial_26_1903" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1035 201.137) rotate(90) scale(181.867 2)">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#737373" />
                </radialGradient>
                <clipPath id="clip0_26_1903">
                    <rect width="1066" height="450" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default C3DSvgImage