import React, { useState } from 'react'
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import jwtAxios from "../../../auth/jwt-api";
import { Button, Form, Input, Select, notification } from "antd";
import getOptionsfrom from '../../../utils/getOptionsFrom';
import styles from "../index.module.scss"

function Company() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { data: company, isLoading } = useQuery(
    ["company", id],
    () => jwtAxios.get(`master/company/${id}`),
    {
      enabled: !!id,
      staleTime: 0,
      cacheTime: 0,
    }
  ); const { data: types } = useQuery(
    ["company-types"],
    () => jwtAxios.get(`master/list-company-types`),

  );

  const handleSubmit = async (data: any) => {
    setLoading(true)
    try {
      await jwtAxios[id ? "put" : "post"](`master/company/${id || ""}`, data)
      notification.success({ message: `Successfully ${id ? "changed" : "updated"}` })
      navigate("/admin/table?page=company")
    } catch (error) {
      console.log({ error });
      notification.error({ message: `Cannot ${id ? "change" : "update"} company` })
    }
    setLoading(false)
  }

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div>
      <h1 style={{ paddingBottom: 30 }}>Company</h1>
      <Form initialValues={company?.data.data || {}} onFinish={handleSubmit} layout="vertical">
        <div className={styles['form-grid']} style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>

          <Form.Item
            name={"name"}
            label="Company name"
            rules={[{ required: true, message: "Please  input this field!" }]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
          {/* <Form.Item
          name={"code"}
          label="Code"
          rules={[{ required: true, message: "Please  input this field!" }]}
          >
          <Input style={{width:200}} />
        </Form.Item> */}
          <Form.Item
            name={"type"}
            label="Type"
            rules={[{ required: true, message: "Please  input this field!" }]}
          >
            <Select style={{ width: 200 }} options={getOptionsfrom(types?.data)} />
          </Form.Item>
        </div>
        <Button loading={loading} htmlType='submit'>Submit</Button>
      </Form>
    </div>
  );
}

export default Company