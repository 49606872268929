import { MenuRouteType } from "../../shared/types";
 import {MdOutlineSegment}  from "react-icons/md"

export const menuConfig: MenuRouteType[] = [
    { id: "company", icon: <MdOutlineSegment  size={20} />,  label: "Companies", link: "/admin/table?page=company" },
    { id: "container",  icon: <MdOutlineSegment  size={20} />,label: "Containers", link: "/admin/table?page=container" },
    { id: "user", icon: <MdOutlineSegment size={20} />, label: "Admins", link: "/admin/table?page=user" },
    { id: "role", icon: <MdOutlineSegment size={20} />, label: "Roles", link: "/admin/table?page=role" },
    { id: "permission", icon: <MdOutlineSegment size={20} />, label: "Permissions", link: "/admin/table?page=permission" },
    // { id: "permission", icon: <MdOutlineSegment  size={20} />, label: "Permissions", link: "/admin/table?page=permission" },
    { id: "menu",  icon: <MdOutlineSegment  size={20} />,label: "Main", link: "/" },
];
 