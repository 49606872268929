import React from "react";
//  import Navbar from "../components/Navbar";
  import { useLocation } from "react-router-dom";
 import styles from "../index.module.scss"
 
function MainLayout({ children }: { children: React.ReactNode }) {
 
  return (
    <  div className={styles['main-layout']} >
 
      {children}

    
    </div >
  );
}

export default MainLayout;
