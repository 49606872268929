import { useState } from 'react';
import { Button, Modal } from 'antd';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { PermissionType } from '../../shared/types/PermissionType';

function PermissionModal({ permissions }: { permissions: PermissionType[] }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <span onClick={() => setOpen(true)} className={styles.button}>
        {permissions.length}
      </span>
      <Modal
        open={open}
        title={"Permissions"
        }
        onCancel={() => setOpen(false)}
        footer={[
          <Button key='back' onClick={() => setOpen(false)}>
            Back
          </Button>,
        ]}>
        <div className={styles.container}>
          {permissions.length ? (
            permissions.map((permission: PermissionType) => (
              <Link
                to={`/admin/permission/${permission.id}?page=permission`}
                key={permission.id}>
                {permission.name}
              </Link>
            ))
          ) : (
            <p style={{ textAlign: 'center' }}>No permissions</p>
          )}
        </div>{' '}
      </Modal>
    </div>
  );
}

export default PermissionModal;
