import React from "react";
import { MainPropType } from "../../shared/types";
import styles from "./index.module.scss";
import { GoHomeFill } from "react-icons/go";
import { Link } from "react-router-dom";

function AuthWrapper({
  children,
  width = 400,
}: { width?: number } & MainPropType) {
  return (
    <div className={styles.wrapper}>
     
      <div className={styles.children} style={{ maxWidth: width }}>
        {children} 
      </div>
    </div>
  );
}

export default AuthWrapper;
