import { Navigate } from "react-router-dom";
import { errorRoutes } from "../error";
import Signin from "./Signin";
import { RouteType } from "../../shared/types";

export const authRoutes: RouteType[] = [
 
  {
    id: 0,
    path: "*",
    element: <Navigate to="/error/not-found" />,
  },  {
    id: 1,
    path: ["/","/auth/signin"],
    element: <Signin />,
  } 
  
];
